import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Button, Typography, CssBaseline, TextField, Box, Container, Grid, useMediaQuery, Dialog, DialogContent, DialogActions, useTheme, IconButton, CardContent, Card, CardMedia, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { actualizarAuth, actualizarDataUsuarioAuth, actualizarMensaje, mostrarModalRegistrar } from "../../../redux/actions/actGlobal";
import { ingresarApp, mostrarError, ingresarAppRegister, obtenerValores, coloresEmma } from "../../../global/js/funciones";
import { BtnCargando, BtnForm } from "../BotonesAccion";
import emma_s1 from "../../../global/imagenes/emma_s1.png";
import cuc from "../../../global/imagenes/logo_cuc.png";
import { mostrarModulo } from "../../../redux/actions/actGlobal";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AppBarModal from '../AppBarModal';
import { BtnFormPage } from '../BotonesAccion';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import TareasTerminadas from '../TareasTerminadas';
import emma_s from "../../../global/imagenes/emma_s.png";
import cuc_amarilla from "../../../global/imagenes/emmaAmarilla.png";
import emmaPalabra from "../../../global/imagenes/emma_palabra.png";
import cuc_emmacompleta from "../../../global/imagenes/emmaamarillacompleta.png";
import logoemma from "../../../global/imagenes/logo_agrupado.png";
import Alert from '@material-ui/lab/Alert';
// import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      position: 'relative', 
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0)', // Fondo transparente
        zIndex: -1, 
      },
    },
  
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: coloresEmma.colorblanco
    },
    '& .MuiInputLabel-outlined': {
      color: coloresEmma.primarycolor,
    },
    '& .MuiInputBase-input': {
      color: coloresEmma.gray
    },

  },
  redBox: {
    display: 'flex',
    background: 'rgba(183, 14, 12, 1)',
    height: '100%',
    clipPath: 'polygon(20% 0, 100% 0, 100% 100%, 0 100%)',
    
    [theme.breakpoints.down('xl')]: {
      maxHeight: '150vh',
      minHeight: '100vh', 
      width: 'auto'
    },
    '@media (min-height: 516px) and (max-height: 615px)': {
      minHeight: '100vh', 
      maxHeight: '150px',
    },
    '@media (min-height: 420px) and (max-height: 515px)': {
      minHeight: '150vh', 
      maxHeight: '220vh',
    },

  '@media (min-height: 300px) and (max-height: 419px)': {
    minHeight: '210vh', 
    maxHeight: '250vh',
  },
  '@media (min-width: 1218px) and (max-width:1678px)': {
    maxHeight: '180vh',
  },
  '@media (min-width: 1000px) and (max-width:1217px)': {
    maxHeight: '180vh',
  },

  '@media (min-width: 800px) and (max-width:1000px)': {
    maxwidth: '180vh',
    maxHeight: '180vh',
  },
  
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '30px'
  },
  paperMobile: {
    paddingTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: { 
      height: '100%', 
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%', 
    },
    '@media (min-height: 300px) and (max-height: 680px)': {
      height: '220px', 
    },
  },
  ContainerRedMovil: {
    '@media (min-height: 300px) and (max-height: 680px)': {
      maxHeight: '420px', 
      paddingBottom: '800px'
    },
  },
  titulo: {
    paddingTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  formMobile: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '75px',
    paddingLeft: '10px',
    paddingRight: '10px',
    maxWidth: "300px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "100%",
  },
  logo_cuc: {
    minWidth: '250px',
    width: "50%",
  },
  div_logo_cuc: {
    textAlign: "center"
  },
  logo_ayuda: {
    width: '70%',
    maxHeight: '250px',
  },
  snackbarStyleViaContentProps: {
    backgroundColor: '#EDF5FA',
    fontSize: '12px',
    // opacity: 0.2,
    color: "black"
  },
  logoAmarillo: {
    marginBottom: '20px',
    width: "50%",
    // minWidth: "230px",
  },
  logoPalabra: {
    width: '50%',
  },
  logoemmacompleta: {
    marginTop: '30px',
    marginBottom: '-65px',
    width: '50%'
  },
  pr_externos: {
    marginTop: '20px',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    color: coloresEmma.colorblanco,
    padding: '0 20px',
    fontWeight: '700'
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      paddingLeft: '20px',
      fontWeight: '700',
      // '& fieldset': {
      //   borderRadius: '10px', // Cambia el border-radius del fieldset
      // },
      '&:hover fieldset': {
        borderColor: '#AEACAC', 
      },
      '&.Mui-focused fieldset': {
        borderColor: '#AEACAC', 
      },
      '& input': {
        padding: '15px 14px'
      }
    },
  },
  botonIn: {
    textTransform: 'capitalize',
    borderRadius: '10px',
    backgroundColor: "#7b817b",
    color: "#b0b1b0",
    fontSize: '12px'
  }
}));


function Registrar(component_props) {
  let { modalRegistrar, mostrarModalRegistrar, enviarDatos, onChange, correoV, identificacion, cargando } = component_props
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={modalRegistrar} onClose={() => mostrarModalRegistrar(false)}>
      <AppBarModal titulo={"¡ Emma Ayuda !"} mostrarModal={() => mostrarModalRegistrar(false)} titulo_accion="Cerrar" accion={() => mostrarModalRegistrar(false)} />
      <form className={classes.form} onSubmit={enviarDatos}>
        <DialogContent className='scroll'>
          <Grid container direction={'row'} justifyContent={'center'} style={{ marginBottom: '30px' }}>
            <Grid item xs={6} md={4} style={isMobile ? { textAlign: 'center' } : {}}>
              <img src={emma_s1} alt="Ayuda Emma" className={classes.logo_ayuda} style={isMobile ? { width: '80px' } : {}} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" color="inherit" align="justify" style={{ 'marginBottom': '15px' }}>
                ¡Hola!, mucho gusto soy EMMA, noto que tienes problemas para ingresar y me gustaria ayudarte, por favor completa los siguientes campos.
              </Typography>
              <Grid item xs={12} style={{ 'marginBottom': '20px' }}>
                <TextField
                  value={correoV}
                  required
                  type="text"
                  id="correoV"
                  name="correoV"
                  label="Correo Institucional"
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} style={{ 'marginBottom': '20px' }}>
                <TextField
                  value={identificacion}
                  required
                  type="number"
                  id="identificacion"
                  name="identificacion"
                  label="Identificacion"
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
              <Button style={{ 'marginBottom': '20px' }} color="primary" type="submit" id='enviar_form_enviar' className="oculto"> Enviar </Button>
              <BtnCargando texto='Enviar' callback={() => document.getElementById('enviar_form_enviar').click()} fullWidth={true} cargando={cargando} />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog >
  )
}


function Ingresar({ actualizarAuth, actualizarDataUsuarioAuth, auth, usuario, actualizarMensaje, modalRegistrar, mostrarModalRegistrar, correoV, identificacion, onChange }) {
  const classes = useStyles();
  const [cargando, setCargando] = React.useState(false);
  const [codigo, setcodigo] = React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [registro, setRegistro] = React.useState(0);
  const [intentos, setIntentos] = React.useState(0);
  const [swBtn, setSwBtn] = React.useState(true);
  const [correoData, setcorreoData] = React.useState('');
  const [contrasenaData, setcontrasenaData] = React.useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const modulo = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [modalProcesosExternos, setModalProcesosExternos] = React.useState(false);
  const [procesosExternos, setProcesosExternos] = React.useState([])
  const [cargandoProcesosExternos, setCargandoProcesosExternos] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    let correo = correoData.trim()
    let password = contrasenaData
    let txtcodigo = document.getElementById('codigo').value
    setCargando(true);
    let { error, estado, resp } = await ingresarApp({ correo, password, codigo, txtcodigo, intentos, registro });
    setCargando(false);
    let titulo = 'Ha ocurrido un error, contacte con el administrador.'/*, tipo = 'error'*/;
    if (!error) {
      if (estado === 200) {
        localStorage.setItem('token', resp?.token);
        actualizarAuth(true);
        return true;
      } else {
        // tipo = 'info';
        titulo = resp?.titulo ? resp?.titulo : mostrarError(resp);
      }
    }
    setMensaje(titulo)
    setcodigo(resp?.codigo ? true : false)
    if (resp?.registro) {
      setRegistro(registro + 1)
    } else if (resp?.intentos) {
      setIntentos(intentos + 1)
    } else if (resp?.dis_btn) {
      setSwBtn(false)
    } else if (resp?.show_modal) {
      mostrarModalRegistrar(true)
    }
  }
  const enviarDatos = async (e) => {
    e.preventDefault();

    let correoV = document.getElementById('correoV').value
    let identificacion = document.getElementById('identificacion').value
    setCargando(true);
    let { error, estado, resp } = await ingresarAppRegister({ correoV, identificacion });
    setCargando(false);
    let titulo = resp?.titulo
    setMensaje(titulo)
    setRegistro(0)
    setIntentos(0)
    mostrarModalRegistrar(false)
  }

  const onChangeForm = ({ target }) => {
    setIntentos(0)
    setRegistro(0)
    let { value, name } = target;
    if (name == 'correo'){
      const cleanedValue = value.replace(/[^a-zA-Z0-9@.]/g, '');
      setcorreoData(cleanedValue);
    } else if (name == 'contrasena') setcontrasenaData(value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    modulo(mostrarModulo({ ruta: '/', nombre: 'ingresar' }));
    obtenerProcesosExternos()
  }, []);

  const obtenerProcesosExternos = async () => {
    setCargandoProcesosExternos(true)
    const procesosExternosConsulta = await obtenerValores(39)
    setProcesosExternos(procesosExternosConsulta);
    setCargandoProcesosExternos(false);
  }

  return (
    <>
      <Grid container>
        {
          isMobile ?
            (
              <>
                <Grid item xs={12} md={12}  className={classes.ContainerRedMovil} style={{
                  backgroundColor: coloresEmma.secondarycolor,
                  height: '122vh', width: '100%'
                }}>
                  <Container component="main" maxWidth="xs" >
                    <Registrar
                      {...{
                        correoV,
                        identificacion,
                        modalRegistrar,
                        mostrarModalRegistrar,
                        onChange,
                        enviarDatos,
                        cargando
                      }} />
                    <CssBaseline />
                    <div className={classes.paperMobile}>
                    <div>
                      <Grid  className="customGridContainer" style={{
                        width: '46vh',
                        justifycontent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: coloresEmma.primarycolor,
                        borderBottomLeftRadius: "68%",
                        borderBottomRightRadius: "68%",
                      }}>
                          <Typography variant="h9" style={{ color: coloresEmma.colorblanco, paddingTop: '30px' }}>
                          <Box fontWeight="fontWeightBold" m={1} textAlign="center">
                            ENTORNO MULTIMEDIA
                          </Box>
                          <Box fontWeight="fontWeightBold" m={1} textAlign="center">
                            DE ACCESO A LA U
                          </Box>
                        </Typography>
                        <img src={cuc_emmacompleta} alt="Logo nueva_emma" className={classes.logoemmacompleta} />
                      </Grid>
                      </div>
                      <div className='alertamobil'>
                      {mensaje && (
                        <Alert severity="info" color="warning">
                            {mensaje}
                        </Alert>
                      )}
                      </div> 
                      <form className={classes.formMobile} onSubmit={onSubmit}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="correo"
                          placeholder="Correo"
                          name="correo"
                          value={correoData}
                          autoFocus
                          className={codigo ? 'oculto' : classes.textField}
                          onChange={onChangeForm}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="contrasena"
                          placeholder="Contraseña"
                          type="password"
                          id="contrasena"
                          className={codigo ? 'oculto' : classes.textField}
                          onChange={onChangeForm}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required={codigo ? true : false}
                          fullWidth
                          name="codigo"
                          label="Codigo"
                          type="text"
                          id="codigo"
                          className={!codigo ? 'oculto' : classes.textField}
                        />
                        <Button color="primary" type="submit" id='enviar_form_ingresar' className="oculto"> Ingresar </Button>
                        {swBtn ?
                          <BtnCargando texto={codigo ? 'Validar' : 'Ingresar'} callback={() => document.getElementById('enviar_form_ingresar').click()} fullWidth={true} cargando={cargando} />
                          : <Button type='submit' variant="contained" className={classes.botonIn} disabled={true} fullWidth={true} onClick={() => { }}>{codigo ? 'Validar' : 'Ingresar'}</Button>
                        }
                      </form>
                      <Button className={classes.pr_externos} onClick={() => setModalProcesosExternos(true)} >Tu paso hacia la universidad comienza aquí. ¡Inscríbete ahora!</Button>
                      <Box mt={3}>
                    </Box>
                    <div className={classes.div_logo_cuc}>
                    <img src={logoemma} alt="Logo cuc" className={classes.logo_agrupado} />
                  </div>
                  <Typography variant="h9" style={{ color: coloresEmma.colorblanco, textAlign: "center" }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                      Powered by CUC Universidad de la Costa
                    </Box>
                  </Typography>
                    </div>
                  </Container>
                </Grid>

              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <div class = "sin fondo" style={{height: "100%",
                  width: '100%'}}>
                  <div className={classes.titulo}>
                    <Typography variant="h3" style={{ color: coloresEmma.colorblanco }}>
                      <Box style={{ fontWeight: '400' }} m={1}>
                        Bienvenido(a) <br /> Aquí encontrarás
                      </Box>
                      <Box fontWeight="fontWeightBold" m={1} letterSpacing={2}>
                        TUS PROCESOS <br /> ACADÉMICOS
                      </Box>
                    </Typography>
                    <img src={cuc} width="40%" style={{ marginTop: '30px' }} />
                    <div>
                    <Typography variant="h9" style={{ color: coloresEmma.colorblanco, position: 'fixed', left: 0, bottom: 0 }}>
                      <Box fontWeight="fontWeightBold" m={1}>
                        Powered by Universidad de la Costa
                      </Box>
                    </Typography>
                  </div>
                  <Box mt={3}>
                  </Box>
                  <Snackbar
                    TransitionComponent={Slide}
                    open={open}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', marginleft: '80px' }}
                    autoHideDuration={60000}
                    // anchorOrigin={{
                    //   vertical: "center",
                    //   horizontal: "center"
                    // }}
                    ContentProps={{
                      "aria-describedby": "message-id",
                      className: classes.snackbarStyleViaContentProps,
                      style: { marginLeft: '100px', marginBottom: '60px' },
                    }}
                    onClose={handleClose}
                    message={
                      <span id="message-id">
                        <div>Si necesita ayuda con su usuario, por favor contáctenos en tecnologia@cuc.edu.co</div>
                      </span>
                    }
                    action={action}
                  />
                  </div>
                  </div>
                
                </Grid>
                <Grid item xs={12} md={6} className={classes.redBox}>
                <div class = "con fondo" style={{height: "100%",
                  width: '100%'}}>
                  <Container component="main" maxWidth="xs" >
                    <Registrar
                      {...{
                        correoV,
                        identificacion,
                        modalRegistrar,
                        mostrarModalRegistrar,
                        onChange,
                        enviarDatos,
                        cargando
                      }} />
                    <CssBaseline />
                    <div className={classes.paper}>
                      <img src={cuc_amarilla} alt="Logo nueva_emma" className={classes.logoAmarillo} />
                      <img src={emmaPalabra} alt="Logo nueva_emma" className={classes.logoPalabra} />
                      <Typography variant="h9" style={{ color: coloresEmma.colorblanco }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          ENTORNO MULTIMEDIA DE ACCESO A LA U
                        </Box>
                      </Typography>
                      {mensaje && (
                        <Alert severity="info" color='warning'>
                            {mensaje}
                        </Alert>
                        
                      )}
                      <form className={classes.form} onSubmit={onSubmit}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="correo"
                          placeholder="Correo"
                          name="correo"
                          value={correoData}
                          autoFocus
                          className={codigo ? 'oculto' : classes.textField}
                          onChange={onChangeForm}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="contrasena"
                          placeholder="Contraseña"
                          type="password"
                          id="contrasena"
                          className={codigo ? 'oculto' : classes.textField}
                          onChange={onChangeForm}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required={codigo ? true : false}
                          fullWidth
                          name="codigo"
                          placeholder="Codigo"
                          type="text"
                          id="codigo"
                          className={!codigo ? 'oculto' : classes.textField}
                        />
                        <Button color="primary" type="submit" id='enviar_form_ingresar' className="oculto"> Ingresar1 </Button>
                        {swBtn ?
                          <BtnCargando texto={codigo ? 'Validar' : 'Ingresar'} callback={() => document.getElementById('enviar_form_ingresar').click()} fullWidth={true} cargando={cargando} />
                          : <Button type='submit' variant="contained" className={classes.botonIn} disabled={true} fullWidth={true} onClick={() => { }}>{codigo ? 'Validar' : 'Ingresar'}</Button>
                        }
                      </form>
                      <Button className={classes.pr_externos} onClick={() => setModalProcesosExternos(true)} >Tu paso hacia la universidad comienza aquí. ¡Inscríbete ahora!</Button>
                    </div>
                  </Container>
                  </div>
                </Grid>
              </>
            )
        }
        <Dialog fullWidth={true} maxWidth="md" open={modalProcesosExternos} onClose={() => setModalProcesosExternos(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Procesos Externos !' mostrarModal={() => setModalProcesosExternos(false)} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <Grid container className='margin_cont' >
              {cargandoProcesosExternos ?
                <TareasTerminadas
                  mensaje="Cargando..."
                  marginTop="7%"
                  imagen={emma_s}
                  widthImg="12%"
                  cargando={true}
                /> :
                procesosExternos.map(procesoExterno => (
                  <Grid item xs={12} sm={6} md={4} key={procesoExterno.id}>
                    <Card style={{
                      border: '1px solid #BDBBBB',
                      borderRadius: '30px',
                      margin: 'auto',
                      marginTop: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                      width: 200,
                      maxWidth: 250,
                    }}>
                      <Link to={procesoExterno.valora} className='sin_decorador' >
                        <CardActionArea style={{ color: 'rgb(199, 158, 50, 0.5)' }}>
                          <CardMedia component="img" image={`https://backend.cuc.edu.co${procesoExterno.archivo}`} style={{
                            margin: '0 auto',
                            width: '80px',
                            paddingTop: '20px',
                            paddingBottom: '10px',
                          }} />
                          <CardContent>
                            <Typography gutterBottom variant="body1" component="body1" style={{ textTransform: 'uppercase', color: '#000000', fontSize: 13 }}>
                              {procesoExterno.nombre}
                            </Typography>
                          </CardContent>
                          <div className="boxAmarilla"></div>
                        </CardActionArea>
                      </Link>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => setModalProcesosExternos(false)} />
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  let { auth, usuario, modalRegistrar } = state.redGlobal;
  return {
    auth,
    usuario,
    modalRegistrar,
  }
};

const mapDispatchToProps = {
  actualizarAuth,
  actualizarDataUsuarioAuth,
  actualizarMensaje,
}
//Props del componente
Ingresar.propTypes = {
  actualizarAuth: PropTypes.func.isRequired,
  actualizarDataUsuarioAuth: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  MostrarmensajeAyuda: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(Ingresar)
