import React, { Component } from 'react';
import { IconButton, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, OutlinedInput, Checkbox, ListItemText, ButtonGroup, InputAdornment, List, ListItem, Link, Table, TableBody, TableRow, TableCell, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TareasTerminadas from '../../../general/TareasTerminadas';
import AppBarModal from '../../../general/AppBarModal';
import InputFile from '../../../general/InputFile';
import emma_w from '../../../../global/imagenes/emma_w.png';
import { BtnForm } from '../../../general/BotonesAccion';
import { getError } from '../../../../global/js/funciones';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CODIGOS_TIPO_ACTIVIDAD_NO_APLICA, CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD, CODIGO_TIPO_CONCEPTO_OTRO, MODALIDADES_VIRTUALES, OTRA_PLATAFORMA, OTRO_SUBTIPO_ACTIVIDAD, OTRO_TIPO_ACTIVIDAD, TIPO_FIN_INSTITUCIONAL, TIPO_MOV_DOCENTE, TIPO_MOV_ESTUDIANTE, TIPO_PARTICIPANTE_ADMIN, TIPO_PARTICIPANTE_DOC, TIPO_PARTICIPANTE_ESTU } from '../helper';
import { Person, PersonAdd, Save, Cancel, LocalLibrary, Class } from '@material-ui/icons/';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale'
import EnviarArchivo from '../../../general/EnviarArchivo';
import BuscarPersona from '../../../general/BuscarPersona';
import ConvocatoriasBuscarConvenio from '../../convocatorias/ConvocatoriasBuscarConvenio';
import AddIcon from '@material-ui/icons/Add';
import ConfirmarAccion from '../../../general/ConfirmarAccion';
import DeleteIcon from '@material-ui/icons/Delete';
import emma_s1 from '../../../../global/imagenes/emma_s1.png';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    border: '1px solid #BDBBBB',
    borderRadius: '30px',
    margin: 'auto',
    marginTop: '10px',
    fontSize: '10px',
    textAlign: 'center',
    width: 200,
    maxWidth: 250,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  img: {
    margin: '0 auto',
    width: '100px',
    paddingTop: '20px',
    paddingBottom: '10px',
  },
  colorEmma: {
    color: 'rgb(199, 158, 50, 0.5)',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contFooter: {
    width: '95%',
    backgroundColor: '#c79e32',
    textAlign: 'center',
    borderRadius: '12px',
  },
  textoFooter: {
    textTransform: 'uppercase',
    color: 'white',
    fontSize: 13,
  },
  inactiva: {
    opacity: '0.5', /* Opacidad reducida */
    // filter: 'blur(2px)'/* Filtro de desenfoque */
  }
});

class AgregarActividad extends Component {
  render() {
    let { mostrarForm, cargandoForm, errores, tipo_actividad, tipos_actividades, otro_tipo_actividad, subtipo_actividad, subtipos_actividades, otro_subtipo_actividad, horas, institucion_seleccionada, instituciones, modal_buscar_institucion, modal_quitar_institucion, fecha_inicio, fecha_fin, responsable_actividad, tipos_responsables, administrativos_responsable, administrativos_resp, dpto_responsable, departamentos_resp, facultad_responsable, facultades_resp, titulo_tema, descripcion_actividad, resultados_descripcion, info_complementaria, institucion, departamento_docente } = this.props.state
    let { onChange, onChangeDate, onChangeFile, onChangeMulti, actualizarEstado, seleccionarInstitucionActividad, fileInput } = this.props.actions
    let { instructivo } = this.props
    const CHARACTER_LIMIT = 750;
    const seleccion = institucion_seleccionada ? instituciones.find(inst => inst.id === institucion_seleccionada) : { id: 0 };

    const quitarInstiListarDatos = (id) => {
      const inst = instituciones.filter(inst => inst.id !== id);
      actualizarEstado('instituciones', inst);
    };

    // if (info_complementaria) {
    //   let f = info_complementaria.includes("fakepath") ? document.getElementById('info_complementaria').files[0] : '';
    //   console.log(f)
    //   onChangeFile(f)
    //   // this.props.actions.setState({ info_complementaria_f: f });
    //   // archivoSend = modelo_convenio.includes("fakepath") ? document.getElementById('modelo_convenio').files[0] : '';

    // }

    const onClickEliminar = data => data && actualizarEstado('modal_quitar_institucion', true);

    const quitarInstitucionSeleccionada = id => {
      if (id) {
        actualizarEstado('modal_quitar_institucion', false);
        actualizarEstado('instituciones', instituciones.filter(inst => inst.id !== id));
        actualizarEstado('institucion_seleccionada', '');
      }
    };

    return (
      <>
        {
          mostrarForm ?
            <>
              {!cargandoForm ?
                <Grid container spacing={2} style={{}}>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      En esta sección el solicitante debe escoger el departamento al que pertenece.
                    </Alert>
                    <Grid item xs={12} style={{marginTop: '10px'}}>
                      <FormControl className='form-control' required error={getError('departamento_docente', errores).length > 0} size='small'>
                        <InputLabel id='departamento_docente_label'>Departamento del solicitante</InputLabel>
                        <Select
                          labelId='departamento_docente_label'
                          id='departamento_docente'
                          name='departamento_docente'
                          value={departamento_docente}
                          label='Departamento al que pertenece el solicitante'
                          onChange={onChange}
                          fullWidth
                        >
                          {
                            departamentos_resp.length > 0 ?
                            departamentos_resp
                                // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                                .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                              : <MenuItem value=""><em>Seleccione el departamento del solicitante</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('departamento_docente', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* <Typography gutterBottom variant="subtitle2">
                      Información del departamento
                      </Typography> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      En esta sección el solicitante debe registrar la información de la actividad.
                    </Alert>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className='form-control' required error={getError('tipo_actividad', errores).length > 0} size='small'>
                      <InputLabel id='tipo_actividad_label'>Tipo de actividad</InputLabel>
                      <Select
                        labelId='tipo_actividad_label'
                        id='tipo_actividad'
                        name='tipo_actividad'
                        value={tipo_actividad}
                        label='Tipo de actividad'
                        onChange={onChange}
                        fullWidth
                      >
                        {
                          tipos_actividades.length > 0 ?
                            tipos_actividades
                              // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                              .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                            : <MenuItem value=""><em>Seleccione tipo de actividad</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{getError('tipo_actividad', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {OTRO_TIPO_ACTIVIDAD == tipo_actividad &&
                    <Grid item xs={6}>
                      <TextField
                        value={otro_tipo_actividad}
                        error={getError('otro_tipo_actividad', errores).length > 0}
                        helperText={getError('otro_tipo_actividad', errores)}
                        name='otro_tipo_actividad'
                        label='Otro tipo de actividad'
                        onChange={onChange}
                        size='small'
                        fullWidth
                        required
                      />
                    </Grid>
                  }
                  {/* {!(CODIGOS_TIPO_ACTIVIDAD_NO_APLICA.includes(tipo_actividad)) && */}
                  {
                    subtipos_actividades.length > 0 && subtipo_actividad !== CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('subtipo_actividad', errores).length > 0} size='small'>
                        <InputLabel id='subtipo_actividad_label'>Subtipo de actividad</InputLabel>
                        <Select
                          labelId='subtipo_actividad_label'
                          id='subtipo_actividad'
                          name='subtipo_actividad'
                          value={subtipo_actividad}
                          label='Subtipo de actividad'
                          fullWidth
                          onChange={onChange}
                        >
                          {subtipos_actividades.length > 0 ?
                            subtipos_actividades
                              // .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(({ secundario: { id, codigo, nombre } }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                            // .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                            : <MenuItem value=""><em>No hay subtipos</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('subtipo_actividad', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {/* } */}
                  {subtipos_actividades.length > 0 && subtipo_actividad == OTRO_SUBTIPO_ACTIVIDAD &&
                    <Grid item xs={6}>
                      <TextField
                        required
                        value={otro_subtipo_actividad}
                        error={getError('otro_subtipo_actividad', errores).length > 0}
                        helperText={getError('otro_subtipo_actividad', errores)}
                        name='otro_subtipo_actividad'
                        label='Otro subtipo de actividad'
                        onChange={onChange}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                  }
                  {/* {CODIGOS_DURACION_ACTIVIDADES.includes(tipo_actividad) &&
                    <Grid item xs={6}>
                      <TextField
                        value={horas}
                        required
                        error={getError('horas', errores).length > 0}
                        helperText={getError('horas', errores)}
                        type='number'
                        id='horas'
                        name='horas'
                        label='Duración (horas)'
                        fullWidth
                        onChange={onChange}
                        size='small'
                      />
                    </Grid>
                  } */}
                  <Grid item xs={4}>
                    <FormControl className='form-control' required error={getError('instituciones', errores).length > 0} size='small' >
                      <Select
                        id='institucion_seleccionada'
                        name='institucion_seleccionada'
                        value={institucion_seleccionada}
                        fullWidth
                        displayEmpty
                        onChange={onChange}
                        style={{ height: '2.8rem' }}
                      >

                        <MenuItem value="">
                          <em>{`${instituciones.length} institucion(es) seleccionada(s)`}</em>
                        </MenuItem>

                        {instituciones && instituciones.length > 0 &&
                          instituciones.sort(({ id: previousID }, { id: currentID }) => previousID - currentID).map((inst) => <MenuItem key={inst.id} value={inst.id}>{inst.codigo_convenio}{" " + inst.institucion}</MenuItem>)
                        }
                      </Select>
                      <FormHelperText>{getError('instituciones', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <ConvocatoriasBuscarConvenio
                      seleccionar={({ id, institucion, codigo_convenio }) => seleccionarInstitucionActividad({ id, institucion, codigo_convenio })}
                      quitar={({ id }) => quitarInstiListarDatos(id)}
                      // quitar={({ id }) => console.log(id)}
                      // quitar={({ id }) => actualizarEstado('instituciones', instituciones.filter(inst => inst.id !== id))}
                      seleccionadas={instituciones}
                      modalBuscarUniversidad={modal_buscar_institucion}
                      mostrarModalBuscarUniversidad={(modal_buscar_institucion) => actualizarEstado('modal_buscar_institucion', modal_buscar_institucion)}
                    />
                    <ConfirmarAccion
                      mensaje1={institucion_seleccionada && `Se quitará ${seleccion.institucion} de la lista, `}
                      ejecutarAccion={quitarInstitucionSeleccionada}
                      mostrarModalConfAccion={(modal_quitar_institucion) => actualizarEstado('modal_quitar_institucion', modal_quitar_institucion)}
                      modalConAccion={modal_quitar_institucion}
                      dato={institucion_seleccionada ? seleccion : { id: 0 }}
                      titulo={'¿ Quitar institución ?'}
                    />
                    <IconButton aria-label="add" style={styles.colorDelete} onClick={() => actualizarEstado('modal_buscar_institucion', true)}>
                      <AddIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => onClickEliminar(institucion_seleccionada)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        id='fecha_inicio'
                        error={getError('fecha_inicio', errores).length > 0}
                        helperText={getError('fecha_inicio', errores)}
                        label='Fecha de inicio'
                        name='fecha_inicio'
                        format='dd/MM/yyyy'
                        value={fecha_inicio}
                        onChange={(date) => onChangeDate('fecha_inicio', date)}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        required
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id='fecha_fin'
                        required
                        error={getError('fecha_fin', errores).length > 0}
                        helperText={getError('fecha_fin', errores)}
                        label='Fecha final'
                        name='fecha_fin'
                        format="dd/MM/yyyy"
                        value={fecha_fin}
                        onChange={(date) => onChangeDate('fecha_fin', date)}
                        fullWidth
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className='form-control' required error={getError('responsable_actividad', errores).length > 0}>
                      <InputLabel id='responsable_actividad_label'>Seleccione responsable/s de la actividad</InputLabel>
                      <Select
                        labelId='responsable_actividad_label'
                        required
                        id='responsable_actividad'
                        name='responsable_actividad'
                        error={getError('responsable_actividad', errores).length > 0}
                        helperText={getError('responsable_actividad', errores)}
                        value={responsable_actividad}
                        fullWidth
                        onChange={onChange}
                        multiple
                        renderValue={selected => {
                          return onChangeMulti(selected, tipos_responsables, 'codigo')
                        }}
                      >
                        {
                          tipos_responsables.map(({ nombre, id, codigo }) => {
                            return (
                              <MenuItem key={id} value={codigo}>
                                <Checkbox checked={responsable_actividad.indexOf(codigo) > -1} />
                                <ListItemText primary={nombre} />
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                      <FormHelperText>{getError('responsable_actividad', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {responsable_actividad.includes('tipo_resp_admin') &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('administrativos_responsable', errores).length > 0}>
                        <InputLabel id='ud_administrativa_responsable_label'>Unidad administrativa responsable/s de la actividad</InputLabel>
                        <Select
                          required
                          labelId='ud_administrativa_responsable_label'
                          id='administrativos_responsable'
                          name='administrativos_responsable'
                          error={getError('administrativos_responsable', errores).length > 0}
                          helperText={getError('administrativos_responsable', errores)}
                          value={administrativos_responsable}
                          fullWidth
                          onChange={onChange}
                          multiple
                          renderValue={selected => {
                            return onChangeMulti(selected, administrativos_resp, 'id')
                          }}
                        >
                          {
                            administrativos_resp.map(({ nombre, id }) => {
                              return (<MenuItem key={id} value={id}>
                                <Checkbox checked={administrativos_responsable.indexOf(id) > -1} />
                                <ListItemText primary={nombre} />
                              </MenuItem>)
                            })
                          }
                        </Select>
                        <FormHelperText>{getError('administrativos_responsable', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {responsable_actividad.includes('tipo_resp_dpto') &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('dpto_responsable', errores).length > 0}>
                        <InputLabel id='facultad_departamento_label'>Departamento responsable/s de la actividad</InputLabel>
                        <Select
                          required
                          labelId='facultad_departamento_label'
                          id='dpto_responsable'
                          name='dpto_responsable'
                          error={getError('dpto_responsable', errores).length > 0}
                          helperText={getError('dpto_responsable', errores)}
                          value={dpto_responsable}
                          fullWidth
                          onChange={onChange}
                          multiple
                          renderValue={selected => {
                            return onChangeMulti(selected, departamentos_resp, 'id')
                          }}
                        >
                          {
                            departamentos_resp
                              .map(({ nombre, id }) => {
                                return (
                                  <MenuItem key={id} value={id}>
                                    <Checkbox checked={dpto_responsable.indexOf(id) > -1} />
                                    <ListItemText primary={nombre} />
                                  </MenuItem>)
                              })
                          }
                        </Select>
                        <FormHelperText>{getError('departamento_sel', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {responsable_actividad.includes('tipo_resp_facul') &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('facultad_responsable', errores).length > 0}>
                        <InputLabel id='facultad_responsable_label'>Facultad responsable/s de la actividad</InputLabel>
                        <Select
                          required
                          labelId='facultad_responsable_label'
                          id='facultad_responsable'
                          name='facultad_responsable'
                          error={getError('facultad_responsable', errores).length > 0}
                          helperText={getError('facultad_responsable', errores)}
                          value={facultad_responsable}
                          fullWidth
                          onChange={onChange}
                          multiple
                          renderValue={selected => {
                            return onChangeMulti(selected, facultades_resp, 'id')
                          }}
                        >
                          {
                            facultades_resp.map(({ nombre, id }) => {
                              return (
                                <MenuItem key={id} value={id}>
                                  <Checkbox checked={facultad_responsable.indexOf(id) > -1} />
                                  <ListItemText primary={nombre} />
                                </MenuItem>)
                            })
                          }
                        </Select>
                        <FormHelperText>{getError('facultad_responsable', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <TextField
                      value={titulo_tema}
                      required
                      error={getError('titulo_tema', errores).length > 0}
                      helperText={getError('titulo_tema', errores)}
                      id='titulo_tema'
                      name='titulo_tema'
                      label='Titulo del evento o tema tratado'
                      fullWidth
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={descripcion_actividad}
                      multiline
                      required
                      error={getError('descripcion_actividad', errores).length > 0}
                      helperText={`${descripcion_actividad.length}/${CHARACTER_LIMIT}`}
                      rows={4}
                      id='descripcion_actividad'
                      name='descripcion_actividad'
                      label='Descripción de la actividad'
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: CHARACTER_LIMIT }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={resultados_descripcion}
                      multiline
                      required
                      error={getError('resultados_descripcion', errores).length > 0}
                      helperText={`${resultados_descripcion.length}/${CHARACTER_LIMIT}`}
                      rows={4}
                      id='resultados_descripcion'
                      name='resultados_descripcion'
                      label='Descripción de resultados y/o productos obtenidos'
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: CHARACTER_LIMIT }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className='oculto'
                      ref={fileInput}
                      type="file"
                      id="info_complementaria"
                      name="info_complementaria"
                      label='Anexar información complementaria'
                      fullWidth
                      autoComplete="billing address-line1"
                      onChange={(e) => onChangeFile(e, document.getElementById('info_complementaria').files[0])}
                    />
                    <Grid item xs={12}>
                      <InputFile
                        label='Anexar información complementaria'
                        required={false}
                        id='info_complementaria'
                        value={info_complementaria}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />}
            </>
            :
            <>
              <TareasTerminadas
                mensaje={
                  <React.Fragment>
                    En el siguiente enlace encontrarás el instructivo para el registro de movilidades. <br />
                    <Link href={instructivo} target='_blank'>
                      ¡ Clic Aquí ! Instructivo Registro de Movilidades
                    </Link> <br /><br />
                    Para comenzar el registro, haz clic en el botón que aparece a continuación
                  </React.Fragment>
                }
                marginTop='4%'
                imagen={emma_s1}
                widthImg="9%"
                style={{ minWidth: '60%' }}
              />
              <div style={{ textAlign: 'center', position: 'sticky', bottom: '0', zIndex: '1000', backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} onClick={() => actualizarEstado('mostrarForm', true)}>Añadir actividad<AddIcon style={{ marginLeft: '0.6rem' }} /></Button>
              </div>
            </>
        }
      </>
    )
  }
}

class AgregarParticipante extends Component {
  render() {
    let { modalAddParticipante, modalAddParticipantExt, errores, tipos_identificacion, cargandoForm, participantes, datosParticipante: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, identificacion, nacionalidad, correo_personal, indicativo_celular, celular, tipo_identificacion, correo } } = this.props.state
    let { actualizarEstado, guardarParticipanteNuevoInt, guardarParticipanteEmma, onChangeParticipante } = this.props.actions
    let { editar } = this.props
    return (
      <>
        {
          modalAddParticipante &&
          <Dialog fullWidth={true} maxWidth='sm' open={modalAddParticipante} onClose={() => actualizarEstado('modalAddParticipante', false)} disableBackdropClick={true}>
            <AppBarModal titulo={'¡ Agregar Participante !'} mostrarModal={(modalAddParticipante) => actualizarEstado('modalAddParticipante', modalAddParticipante)} />
            <DialogContent className='scroll'>
              <BuscarPersona
                callback={({
                  id,
                  primer_nombre,
                  segundo_nombre,
                  primer_apellido,
                  segundo_apellido,
                  tipo_identificacion,
                  identificacion,
                  correo,
                  correo_personal,
                  indicativo_celular,
                  celular,
                  nacionalidad
                }) =>
                  guardarParticipanteEmma({
                    id,
                    primer_nombre,
                    segundo_nombre: segundo_nombre ? segundo_nombre : '',
                    primer_apellido,
                    segundo_apellido: segundo_apellido ? segundo_apellido : '',
                    tipo_identificacion: tipo_identificacion ? tipo_identificacion.id : '',
                    identificacion: identificacion ? identificacion : '',
                    correo: correo ? correo : '',
                    correo_personal: correo_personal ? correo_personal : '',
                    indicativo_celular: indicativo_celular ? indicativo_celular : '',
                    celular: celular ? celular : '',
                    nacionalidad: nacionalidad ? nacionalidad : ''
                  })
                }
                persona_inter={true} // Buscar solo personas internas
                seleccionarVarios={true} // Poder seleccionar varios
                listaSeleccionados={participantes} // Lista para ir emparejando con los que ya están seleccionados
              // buscarEstudiante = { TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad) ? '1' : '0' } // Filtro para buscar solo estudiantes o profesores
              // buscarTipo = { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ? '2' : '1' } // Filtro para buscar solo entrantes o salientes
              />
            </DialogContent>
            <DialogActions>
              <>
                <BtnForm texto="AGREGAR PERSONA NUEVA" callback={(modalAddParticipantExt) => actualizarEstado('modalAddParticipantExt', modalAddParticipantExt)} />
                <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddParticipante', false)} />
                {/* <BtnForm texto="AGREGAR" callback={guardarParticipanteInt} /> */}
              </>
            </DialogActions>
          </Dialog>
        }
        {
          modalAddParticipantExt &&
          <Dialog fullWidth={true} maxWidth='sm' open={modalAddParticipantExt} onClose={(modalAddParticipantExt) => actualizarEstado('modalAddParticipantExt', modalAddParticipantExt)} disableBackdropClick={true}>
            <AppBarModal titulo={!editar ? '¡ Agregar Participante Externo !' : 'Editar Participante Externo'} mostrarModal={(modalAddParticipantExt) => actualizarEstado('modalAddParticipantExt', modalAddParticipantExt)} />
            <DialogContent className='scroll'>
              {
                !cargandoForm ?
                  <Grid container spacing={3} style={{ marginTop: '0.2%', marginBottom: '2%' }} >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Chip label="Información del Participante" variant="outlined" color="primary" icon={<Person />} />
                      {/* <Chip label="Información del Participante" color="primary" icon={<Person />} style={{color:'white'}} /> */}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='primer_nombre'
                        label='Primer nombre'
                        name='primer_nombre'
                        value={primer_nombre}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('primer_nombre', errores).length > 0}
                        helperText={getError('primer_nombre', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='segundo_nombre'
                        label='Segundo nombre'
                        name='segundo_nombre'
                        value={segundo_nombre}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='primer_apellido'
                        label='Primer apellido'
                        name='primer_apellido'
                        value={primer_apellido}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('primer_apellido', errores).length > 0}
                        helperText={getError('primer_apellido', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='segundo_apellido'
                        label='Segundo apellido'
                        name='segundo_apellido'
                        value={segundo_apellido}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className='form-control' error={getError('tipo_identificacion', errores).length > 0} variant='outlined' required color='primary' size='small'>
                        <InputLabel id='tipo_identificacion_label'>Tipo de identificación</InputLabel>
                        <Select
                          id='tipo_identificacion'
                          label='Tipo de identificación'
                          labelId='tipo_identificacion_label'
                          name='tipo_identificacion'
                          value={tipo_identificacion}
                          onChange={onChangeParticipante}
                          size='small'
                          fullWidth
                        >
                          {
                            tipos_identificacion
                              .filter(({ valorg }) => valorg == '1')
                              .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{getError('tipo_identificacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='identificacion'
                        label='Número de identificación'
                        name='identificacion'
                        value={identificacion}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('identificacion', errores).length > 0}
                        helperText={getError('identificacion', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='indicativo_celular'
                        label='Indicativo del país'
                        name='indicativo_celular'
                        value={indicativo_celular}
                        onChange={onChangeParticipante}
                        type='number'
                        placeholder='Ej: 57'
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField // se agrego (celular)
                        id='celular'
                        label='Celular'
                        name='celular'
                        value={celular}
                        onChange={onChangeParticipante}
                        type='number'
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='correo_personal'
                        label='Correo Personal'
                        name='correo_personal'
                        value={correo_personal}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='correo'
                        label='Correo Institucional'
                        name='correo'
                        value={correo}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='nacionalidad'
                        label='Nacionalidad'
                        name='nacionalidad'
                        value={nacionalidad}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('nacionalidad', errores).length > 0}
                        helperText={getError('nacionalidad', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={12} >
                  <div style={{ textAlign: 'center', position: 'sticky', bottom: '0', zIndex: '1000', backgroundColor: 'white' }}>
                    <Button variant='outlined' size="small" startIcon={<Cancel />} style={{ color: 'red' }} > CANCELAR </Button>
                    <Button variant='outlined' color="primary" size="small" startIcon={<Save />}  > GUARDAR </Button>
                  </div>
                </Grid> */}
                  </Grid>
                  : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
              }
            </DialogContent>
            <DialogActions>
              <>
                <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddParticipantExt', false)} />
                <BtnForm texto="AGREGAR" callback={guardarParticipanteNuevoInt} />
              </>
            </DialogActions>
          </Dialog>
        }

      </>

    )
  }
}

AgregarParticipante.propTypes = {
  state: PropTypes.shape({
    modalAddParticipante: PropTypes.bool,
    modalAddParticipantExt: PropTypes.bool,
    errores: PropTypes.object,
    tipos_identificacion: PropTypes.array,
    cargandoForm: PropTypes.bool,
    participantes: PropTypes.array,
    datosParticipante: PropTypes.shape({
      primer_nombre: PropTypes.string,
      segundo_nombre: PropTypes.string,
      primer_apellido: PropTypes.string,
      segundo_apellido: PropTypes.string,
      identificacion: PropTypes.string,
      nacionalidad: PropTypes.string,
      correo_personal: PropTypes.string,
      indicativo_celular: PropTypes.string,
      celular: PropTypes.string,
      tipo_identificacion: PropTypes.string,
      correo: PropTypes.string
    })
  }),
  actions: PropTypes.shape({
    actualizarEstado: PropTypes.func,
    guardarParticipanteNuevoInt: PropTypes.func,
    guardarParticipanteEmma: PropTypes.func,
    onChangeParticipante: PropTypes.func
  })
};

AgregarParticipante.defaultProps = {
  state: {
    modalAddParticipante: false,
    modalAddParticipantExt: false,
    errores: {},
    tipos_identificacion: [],
    cargandoForm: false,
    participantes: [],
    datosParticipante: {
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      identificacion: '',
      nacionalidad: '',
      correo_personal: '',
      indicativo_celular: '',
      celular: '',
      tipo_identificacion: '',
      correo: ''
    }
  },
  actions: {
    actualizarEstado: () => { },
    guardarParticipanteNuevoInt: () => { },
    guardarParticipanteEmma: () => { },
    onChangeParticipante: () => { }
  }
};

class AgregarParticipanteMasivo extends Component {
  render() {
    let { modalMasivo, cerrarModal, cargandoForm, enviarParticipantesMasivo, masivos } = this.props;
    return (
      // !cargandoForm ?
      <EnviarArchivo
        modalAdd={modalMasivo}
        mostrarModalAdd={cerrarModal}
        enviarArchivo={(file) => enviarParticipantesMasivo(file)}
        titulo="Cargar Participantes Masivos"
        formatos='Excel'
        mensaje={
          <Grid>
            Tenga en cuenta las siguientes indicaciones:
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      target="_blank"
                      underline='none'
                      href={masivos[0].archivo}
                      style={{ color: '#B22222', textDecoration: 'none' }}
                    >
                      ¡Haga clic para descargar la plantilla y registrar los datos de los participantes!
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText primary='No realice cambios en el formato de la plantilla.' />
              </ListItem>
            </List>
          </Grid>
        }
      />
      // : <TareasTerminadas mensaje={'Cargando..'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />

    )
  }
}

class ModalContenedorAll extends Component {
  renderCards() {
    const { classes } = this.props;
    const { tipos_forms } = this.props.state
    const { handleModalContenedor } = this.props.actions
    const tiposFormsOrdenados = [...tipos_forms].sort((a, b) => a.valorf - b.valorf || a.valorg - b.valorg);
    return tiposFormsOrdenados.map((data, index) => (
      <Grid key={index} item xs={12} sm={6} md={4}>
        <Card className={classes.root} onClick={() => handleModalContenedor(data.codigo)}>
          <CardActionArea className={classes.colorEmma} disabled={index < 3}>
            <CardMedia component="img" image={data.archivo} className={classes.img} />
            <CardContent className={classes.cardContent}>
              <div className={classes.contFooter}>
                <Typography gutterBottom variant="body1" component="body1" className={classes.textoFooter}>
                  {data.nombre}
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ));
  }

  render() {
    const { modalContenedor, cargandoContenedor, tit_cargando } = this.props.state
    const { actualizarEstado } = this.props.actions

    const { classes } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth='md' open={modalContenedor} onClose={() => actualizarEstado('modalContenedor', false)} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Información !'} mostrarModal={(modalContenedor) => actualizarEstado('modalContenedor', modalContenedor)} />
        <DialogContent className='scroll'>
          <Grid container direction='row' justify='center' lg={12} md={12} sm={12} xs={12} style={{ padding: '2% 4% 2% 4%' }} >
            {
              cargandoContenedor ?
                <TareasTerminadas mensaje={tit_cargando} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
                : this.renderCards()
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalContenedor', false)} />
          </>
        </DialogActions>
      </Dialog>
    );
  }
}

class AgregarInfoBasica extends Component {
  render() {
    let { props_info } = this.props;
    let { modalAddInfoBasica, cerrarModal, cargandoForm, errores, onChange, tipos_movilidades, tipos_modalidades, plataformas, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, guardarInfoPrincipalParticipantes } = props_info
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddInfoBasica} onClose={cerrarModal} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Información !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {
            !cargandoForm ?
              <Grid container spacing={3} style={{ marginTop: '1%', marginBottom: '2%' }}>
                {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Chip label="Información de Movilidad" variant="outlined" color="primary" icon={<LocalLibrary />} />
                  <Chip label="Información del Participante" color="primary" icon={<Person />} style={{color:'white'}} />
                </Grid> */}
                <Grid item xs={12} >
                  <FormControl className='form-control' required error={getError('tipo_movilidad', errores).length > 0} size='small'>
                    <InputLabel id='tipo_movilidad_label'>Tipo de movilidad</InputLabel>
                    <Select
                      labelId='tipo_movilidad_label'
                      id='tipo_movilidad'
                      name='tipo_movilidad'
                      value={tipo_movilidad}
                      label='Tipo de movilidad'
                      onChange={onChange}
                      fullWidth
                    >
                      {
                        tipos_movilidades
                          .map(({ secundario: { id, nombre } }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)

                        // .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                      }
                    </Select>
                    <FormHelperText>{getError('tipo_movilidad', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className='form-control' required error={getError('tipo_modalidad', errores).length > 0} size='small'>
                    <InputLabel id='modalidad_label'>Tipo de modalidad</InputLabel>
                    <Select
                      labelId='modalidad_label'
                      id='tipo_modalidad'
                      name='tipo_modalidad'
                      value={tipo_modalidad}
                      label='tipo_modalidad'
                      onChange={onChange}
                      fullWidth
                      disabled={tipo_movilidad !== '' ? false : true}
                    >
                      {tipos_modalidades.map(({ secundario: { id, codigo, nombre } }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                      }
                    </Select>
                    <FormHelperText>{getError('tipo_modalidad', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                {MODALIDADES_VIRTUALES.includes(tipo_modalidad) && //validacion campo plataforma a utilizar
                  <Grid item xs={12} /* xs={plataforma_utilizada && CODIGO_PLATAFORMA_OTRO == plataforma_utilizada ? 6 : 6} */>
                    <FormControl className='form-control' required error={getError('plataforma', errores).length > 0} size='small'>
                      <InputLabel id='plataforma_utilizada_label'>Plataforma utilizada</InputLabel>
                      <Select
                        labelId='plataforma_utilizada_label'
                        id='plataforma'
                        name='plataforma'
                        value={plataforma}
                        label='plataforma'
                        fullWidth
                        onChange={onChange}
                      >
                        {
                          plataformas
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                        }
                      </Select>
                      <FormHelperText>{getError('plataforma', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {MODALIDADES_VIRTUALES.includes(tipo_modalidad) && plataforma && OTRA_PLATAFORMA == plataforma &&
                  <Grid item xs={12}>
                    <TextField
                      required
                      value={otra_plataforma}
                      error={getError('otra_plataforma', errores).length > 0}
                      helperText={getError('otra_plataforma', errores)}
                      id='otra_plataforma'
                      name='otra_plataforma'
                      label='Otra plataforma'
                      fullWidth
                      onChange={onChange}
                      size='small'
                    />
                  </Grid>
                }
              </Grid>
              : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          }
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
            <BtnForm texto="GUARDAR" callback={guardarInfoPrincipalParticipantes} />
          </>
        </DialogActions>
      </Dialog >
    )
  }
}

class AgregarFinanciacion extends Component {
  render() {
    let { props_financiación } = this.props;
    let { modalAddFinanciacion, cerrarModal, cargandoForm, onChange, es_financiado, tipos_financiaciones, tipo_financiacion, errores, dependencia, dependencias, fuente_financiacion, fuentes_financiacion, entidad, valor_cop, tipo_moneda, monedas, valor_ext, participantesSelect, info_inicial_participantes, tipos_conceptos, clasificacion_de_financiacion, otro_tipo_de_concepto, fileInput, comprobante_financiacion, guardarFinanciacionParticipantes } = props_financiación
    const CHARACTER_LIMIT = 750;
    let movilidadEst = ''
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddFinanciacion} onClose={cerrarModal} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Financiación !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {
            !cargandoForm ?
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FormControl className='form-control' required error={getError('es_financiado', errores).length > 0}>
                    <InputLabel id="label_financiacion">¿Hubo financiación de la actividad?</InputLabel>
                    <Select
                      labelId="label_financiacion"
                      id="es_financiado"
                      name='es_financiado'
                      value={es_financiado}
                      onChange={onChange}
                    >
                      <MenuItem value="1">Si</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                    <FormHelperText>{getError('es_financiado', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                {es_financiado == '1' &&
                  <>
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('tipo_financiacion', errores).length > 0}>
                        <InputLabel id='tipo_financiacion_label'>Tipo de financiación</InputLabel>
                        <Select
                          labelId='tipo_financiacion_label'
                          id='tipo_financiacion'
                          name='tipo_financiacion'
                          value={tipo_financiacion}
                          label='tipo_financiacion'
                          fullWidth
                          onChange={onChange}
                        >
                          {tipos_financiaciones
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{getError('tipo_financiacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {tipo_financiacion !== '' &&
                      <>
                        {tipo_financiacion == TIPO_FIN_INSTITUCIONAL ?
                          <Grid item xs={6}>
                            <FormControl className='form-control' required error={getError('dependencia', errores).length > 0}>
                              <InputLabel id='dependencia_label'>Dependencia</InputLabel>
                              <Select
                                labelId='dependencia_label'
                                id='dependencia'
                                name='dependencia'
                                value={dependencia}
                                label='dependencia'
                                fullWidth
                                onChange={onChange}
                              >
                                {dependencias
                                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                  .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                                }
                              </Select>
                              <FormHelperText>{getError('dependencia', errores)}</FormHelperText>
                            </FormControl>
                          </Grid>
                          :
                          <>
                            <Grid item xs={6}>
                              <FormControl className='form-control' required error={getError('fuente_financiacion', errores).length > 0}>
                                <InputLabel id='fuente_financiacion_label'>Fuente de Financiación</InputLabel>
                                <Select
                                  labelId='fuente_financiacion_label'
                                  id='fuente_financiacion'
                                  name='fuente_financiacion'
                                  value={fuente_financiacion}
                                  label='fuente_financiacion'
                                  fullWidth
                                  onChange={onChange}
                                >
                                  {fuentes_financiacion ?
                                    fuentes_financiacion
                                      // .filter(({ valora }) => fuentes_financiacion == TIPO_FINANCIACION_INTERNACIONAL ? valora == 'internacional' : valora == 'nacional')
                                      // .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                      .map((item, index) => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                    : <MenuItem value=""><em>No hay Fuentes de financiación disponibles</em></MenuItem>
                                  }
                                </Select>
                                <FormHelperText>{getError('fuente_financiacion', errores)}</FormHelperText>
                              </FormControl>
                            </Grid>
                            {fuente_financiacion &&
                              <Grid item xs={12}>
                                <TextField
                                  value={entidad}
                                  required
                                  error={getError('entidad', errores).length > 0}
                                  helperText={getError('entidad', errores)}
                                  id='entidad'
                                  name='entidad'
                                  label='Entidad'
                                  fullWidth
                                  onChange={onChange}
                                />
                              </Grid>
                            }
                          </>
                        }
                      </>
                    }
                    <Grid item xs={12}>
                      <TextField
                        value={valor_cop}
                        required
                        type='number'
                        error={getError('valor_cop', errores).length > 0}
                        helperText={getError('valor_cop', errores)}
                        id='valor_cop'
                        name='valor_cop'
                        label={`Indique el valor en pesos colombianos`}
                        fullWidth
                        onChange={onChange}
                        InputProps={{ startAdornment: <InputAdornment position="start">COP$</InputAdornment>, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className='form-control' error={getError('tipo_moneda', errores).length > 0}>
                        <InputLabel id='tipo_moneda_label'>Moneda extranjera</InputLabel>
                        <Select
                          labelId='tipo_moneda_label'
                          id='tipo_moneda'
                          name='tipo_moneda'
                          value={tipo_moneda}
                          label='Moneda extranjera'
                          fullWidth
                          onChange={onChange}
                        >
                          {monedas
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(({ code, name }) => <MenuItem key={name} value={code}>{name}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{getError('tipo_moneda', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {tipo_moneda != 'none' && tipo_moneda != '' &&
                      <>
                        <Grid item xs={6}>
                          <TextField
                            value={valor_ext}
                            type='number'
                            error={getError('valor_ext', errores).length > 0}
                            helperText={getError('valor_ext', errores)}
                            id='valor_ext'
                            name='valor_ext'
                            label={`Equivalencia en moneda extranjera`}
                            fullWidth
                            onChange={onChange}
                            InputProps={{ startAdornment: <InputAdornment position="start">{tipo_moneda ? tipo_moneda : ''}</InputAdornment>, }}
                          />
                        </Grid>
                      </>
                    }
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('clasificacion_de_financiacion', errores).length > 0}>
                        <InputLabel id='clasificacion_de_financiacion_label'>Clasificación de financiación</InputLabel>
                        <Select
                          labelId='clasificacion_de_financiacion_label'
                          id='clasificacion_de_financiacion'
                          name='clasificacion_de_financiacion'
                          value={clasificacion_de_financiacion}
                          label='Clasificación de financiación'
                          fullWidth
                          onChange={onChange}
                        >
                          {tipos_conceptos.length > 0 ?
                            tipos_conceptos
                              // .filter(({ valorg }) => modalidad_escogida == MODALIDAD_VIRTUAL ? valorg == '1' || valorg == '7' : valorg == '1' || valorg == '2' )
                              // .filter(({ valorg }) => valorg == '1' || valorg == '2' )
                              .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(tipo => <MenuItem key={tipo} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                            : <MenuItem value=""><em>No hay clasificaciones disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('clasificacion_de_financiacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {clasificacion_de_financiacion == CODIGO_TIPO_CONCEPTO_OTRO &&
                      <Grid item xs={12}>
                        <TextField
                          value={otro_tipo_de_concepto}
                          error={getError('otro_tipo_de_concepto', errores).length > 0}
                          helperText={`${otro_tipo_de_concepto.length}/${CHARACTER_LIMIT}`}
                          id='otro_tipo_de_concepto'
                          name='otro_tipo_de_concepto'
                          label='Indique el otro tipo de concepto'
                          fullWidth
                          required
                          onChange={onChange}
                          inputProps={{ maxLength: CHARACTER_LIMIT }}
                        />
                      </Grid>
                    }
                    {/* <Grid item xs={12} >
                      <TextField
                        className='oculto'
                        ref={fileInput}
                        type="file"
                        id="comprobante_financiacion"
                        name="comprobante_financiacion"
                        label={'Comprobante de financiación'}
                        fullWidth
                        autoComplete="billing address-line1"
                        onChange={onChange}
                      />
                      <Grid item xs={12}>
                        <InputFile required={false} label={'Comprobante de financiación'} id='comprobante_financiacion' value={comprobante_financiacion} />
                      </Grid>
                    </Grid> */}
                  </>

                }


              </Grid>
              : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          }
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
            <BtnForm texto="GUARDAR" callback={guardarFinanciacionParticipantes} />
          </>
        </DialogActions>
      </Dialog>
    )
  }
}

class AgregarInstitucion extends Component {
  render() {
    let { modalAddInstitucion, cargandoForm, errores, institucion_participante, modal_buscar_institucion, participante_tipo, tipos_participantes, tipo_participante, error, dptos_academicos, dpto_participante, dependencias, programa_participante, facultades_resp, facultad_participante, niveles_educativos, nivel_participante, programas_receptores, programas_receptores_docente, modal_elimi_institucion, ident_tipo_movilidad } = this.props.state
    let { actualizarEstado, agregarInstitucionParticipante, onChange, guardarInstitucionParticipante } = this.props.actions

    const mensajeProps = { marginTop: '7%', imagen: emma_s1, widthImg: '11%' };

    const mensajes = {
      cargando: 'Cargando',
      distinto: (
        <React.Fragment>
          Para añadir una institución, es necesario que los participantes seleccionados pertenezcan al mismo tipo de movilidad. <br />
          Te recomendamos revisar tus selecciones
        </React.Fragment>
      ),
      error: 'Para añadir una institución, es necesario registrar la información básica del participante seleccionado'
    };

    const mensaje = mensajes[cargandoForm ? 'cargando' : participante_tipo === 'distinto' && !error ? 'distinto' : error ? 'error' : null];

    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddInstitucion} onClose={() => actualizarEstado('modalAddInstitucion', false)} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Institución !'} mostrarModal={(modalAddInstitucion) => actualizarEstado('modalAddInstitucion', modalAddInstitucion)} />
        <DialogContent className='scroll'>
          {mensaje && <TareasTerminadas mensaje={mensaje} {...mensajeProps} cargando={cargandoForm} />}
          {participante_tipo !== 'distinto' && !cargandoForm && !error && ident_tipo_movilidad &&
            <>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <TextField
                    value={institucion_participante.institucion}
                    required
                    error={getError('institucion_participante', errores).length > 0}
                    helperText={getError('institucion_participante', errores)}
                    id='institucion_participante'
                    name='institucion_participante'
                    label={ident_tipo_movilidad == 'saliente' ? 'Institución Destino' : ident_tipo_movilidad == 'entrante' ? 'Institución de origen' : 'Institución externa'}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={3} >
                  <ConvocatoriasBuscarConvenio
                    seleccionar={({ id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio }) => agregarInstitucionParticipante(id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio)}
                    quitar={() => actualizarEstado('institucion_participante', { id: 0, institucion: '', pais_vinculante: '', ciudad_vinculante: '', codigo_convenio: '' })}
                    // () => this.setState({ institucion_participante: { id: 0, nombre: '', pais: '', ciudad: '', codigo_convenio: '' } })
                    seleccionadas={[institucion_participante]}
                    modalBuscarUniversidad={modal_buscar_institucion}
                    mostrarModalBuscarUniversidad={(modal_buscar_institucion) => actualizarEstado('modal_buscar_institucion', modal_buscar_institucion)}
                  />
                  <ConfirmarAccion
                    mensaje1={`Se quitará ${institucion_participante.institucion}, `}
                    ejecutarAccion={() => actualizarEstado('institucion_participante', { id: 0, institucion: '', pais_vinculante: '', ciudad_vinculante: '', codigo_convenio: '' })}
                    mostrarModalConfAccion={(modal_elimi_institucion) => actualizarEstado('modal_elimi_institucion', modal_elimi_institucion)}
                    modalConAccion={modal_elimi_institucion}
                    dato={institucion_participante ? institucion_participante : { id: 0 }}
                    titulo={'¿ Quitar institución ?'}
                  />
                  <IconButton aria-label="add" /* className={classes.colorEmmma}  */ onClick={(modal_buscar_institucion) => actualizarEstado('modal_buscar_institucion', modal_buscar_institucion)}>
                    <AddIcon />
                  </IconButton>
                  <IconButton aria-label="clear" /* className={classes.colorDelete} */ onClick={() => actualizarEstado('modal_elimi_institucion', true)}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
                {institucion_participante.id !== 0 &&
                  <>
                    <Grid item xs={6} md={6}>
                      <TextField
                        value={institucion_participante.pais_vinculante ? institucion_participante.pais_vinculante.nombre : institucion_participante ? institucion_participante.pais : ""}
                        required
                        id='pais_movilidad'
                        name='pais_movilidad'
                        label='País de la institución'
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        value={institucion_participante ? institucion_participante.ciudad_vinculante : "No registra"}
                        required
                        disabled="true"
                        id='ciudad_movilidad'
                        name='ciudad_movilidad'
                        label='Ciudad de la institución'
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={12} md={12}>
                  <FormControl className='form-control' required error={getError('tipo_participante', errores).length > 0}>
                    <InputLabel id='tipo_participante_label'>Tipo de participante</InputLabel>
                    <Select
                      labelId='tipo_participante_label'
                      id='tipo_participante'
                      disabled={participante_tipo}
                      name='tipo_participante'
                      value={tipo_participante}
                      fullWidth
                      onChange={onChange}
                    >
                      {tipos_participantes.map((tipo) => <MenuItem key={tipo} value={tipo.id}>{tipo.nombre}</MenuItem>)}
                    </Select>
                    <FormHelperText>{getError('tipo_participante', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                {tipo_participante == TIPO_PARTICIPANTE_ESTU &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControl className='form-control' required error={getError('facultad_participante', errores).length > 0}>
                        <InputLabel id='facultad_receptora_label'>Facultad receptora</InputLabel>
                        <Select
                          labelId='facultad_receptora_label'
                          id='facultad_participante'
                          name='facultad_participante'
                          value={facultad_participante}
                          fullWidth
                          onChange={onChange}
                        >
                          {facultades_resp.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)}
                        </Select>
                        <FormHelperText>{getError('facultad_participante', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {facultad_participante && facultad_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('nivel_participante', errores).length > 0}>
                          <InputLabel id='nivel_educativo_label'>Nivel de estudio</InputLabel>
                          <Select
                            labelId='nivel_educativo_label'
                            id='nivel_participante'
                            name='nivel_participante'
                            value={nivel_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              niveles_educativos.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                            }
                          </Select>
                          <FormHelperText>{getError('nivel_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                    {
                      facultad_participante && facultad_participante !== '' && nivel_participante && nivel_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('programa_participante', errores).length > 0}>
                          <InputLabel id='programa_receptor_label'>Programa receptor</InputLabel>
                          <Select
                            labelId='programa_receptor_label'
                            id='programa_participante'
                            name='programa_participante'
                            value={programa_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              programas_receptores.length > 0 ?
                                programas_receptores
                                  .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                                : <MenuItem value=""><em>No hay programas académicos disponibles</em></MenuItem>
                            }
                          </Select>
                          <FormHelperText>{getError('programa_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </>
                }
                {tipo_participante == TIPO_PARTICIPANTE_DOC &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControl className='form-control' required error={getError('dpto_participante', errores).length > 0}>
                        <InputLabel id='dpto_participante_label'>{ident_tipo_movilidad == 'saliente' ? 'Departamento adscrito' : ident_tipo_movilidad == 'entrante' ? 'Departamento receptor' : 'Institución externa'}</InputLabel>
                        <Select
                          labelId='dpto_participante_label'
                          id='dpto_participante'
                          name='dpto_participante'
                          value={dpto_participante}
                          fullWidth
                          onChange={onChange}
                        >
                          {
                            dptos_academicos.length > 0 ?
                              dptos_academicos
                                .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                              : <MenuItem value=""><em>No hay departamentos académicos disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('dpto_participante', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {dpto_participante && dpto_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('programa_participante', errores).length > 0}>
                          <InputLabel id='programa_receptor_label'>Programa receptor</InputLabel>
                          <Select
                            labelId='programa_receptor_label'
                            id='programa_participante'
                            name='programa_participante'
                            value={programa_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              programas_receptores_docente.length > 0 ?
                                programas_receptores_docente
                                  // .map((item,index) => <MenuItem key = { index } value = { item.secundario.id }>{ item.secundario.nombre }</MenuItem>)
                                  .map(({ secundario }, index) => <MenuItem key={index} value={secundario.id}>{secundario.nombre}</MenuItem>)
                                : <MenuItem value=""><em>No hay programas académicos disponibles</em></MenuItem>
                            }
                          </Select>
                          <FormHelperText>{getError('programa_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </>
                }
                {tipo_participante == TIPO_PARTICIPANTE_ADMIN &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControl className='form-control' required error={getError('dpto_participante', errores).length > 0}>
                        <InputLabel id='dpto_participante_label'>Departamento receptor</InputLabel>
                        <Select
                          labelId='dpto_participante_label'
                          id='dpto_participante'
                          name='dpto_participante'
                          value={dpto_participante}
                          fullWidth
                          onChange={onChange}
                        >
                          {
                            dptos_academicos
                              .length > 0 ?
                              dptos_academicos
                                .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                              : <MenuItem value=""><em>No hay departamentos académicos disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('dpto_participante', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {dpto_participante && dpto_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('programa_participante', errores).length > 0}>
                          <InputLabel id='dependencia_receptora_label'>Dependencia receptora</InputLabel>
                          <Select
                            labelId='dependencia_receptora_label'
                            id='programa_participante'
                            name='programa_participante'
                            value={programa_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              dependencias.length > 0 ?
                                dependencias.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                                : <MenuItem value=""><em>No hay dependencias disponibles</em></MenuItem>
                            }
                          </Select>
                          <FormHelperText>{getError('programa_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </>
                }
              </Grid>
            </>
          }
        </DialogContent>
        <DialogActions>
          {
            participante_tipo == 'distinto' ?
              <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddInstitucion', false)} />
              :
              <>
                <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddInstitucion', false)} />
                <BtnForm texto="GUARDAR" callback={guardarInstitucionParticipante} />
              </>
          }
        </DialogActions>
      </Dialog >
    )
  }
}

// MODALES DETALLE

class DetalleParticipante extends Component {
  render() {
    let { modalDetParticipante, cerrarModal, participanteDetalle, cargando } = this.props;
    let { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion: { nombre }, identificacion, indicativo_celular, celular, correo_personal, correo, nacionalidad } = participanteDetalle;
    let nombre_completo = `${primer_nombre ?? ''} ${segundo_nombre ?? ''} ${primer_apellido ?? ''} ${segundo_apellido ?? ''}`;
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalDetParticipante} onClose={cerrarModal} >
        <AppBarModal titulo={'¡ Detalle Participante !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {cargando ? <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} /> :
            <Table>
              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row">Nombre completo</TableCell>
                  <TableCell align="left">{nombre_completo.toUpperCase()}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell component="th" scope="row">Tipo de identificación</TableCell>
                  <TableCell align="left">{nombre}</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell component="th" scope="row">Número de identificación</TableCell>
                  <TableCell align="left">{identificacion.toUpperCase()}</TableCell>
                </TableRow>
                {
                  indicativo_celular &&
                  <TableRow key={4}>
                    <TableCell component="th" scope="row">Indicativo país</TableCell>
                    <TableCell align="left">+{indicativo_celular}</TableCell>
                  </TableRow>
                }
                {
                  celular &&
                  <TableRow key={5}>
                    <TableCell component="th" scope="row">Celular</TableCell>
                    <TableCell align="left">{celular}</TableCell>
                  </TableRow>
                }
                {
                  correo_personal &&
                  <TableRow key={6}>
                    <TableCell component="th" scope="row">Correo personal</TableCell>
                    <TableCell align="left">{correo_personal}</TableCell>
                  </TableRow>
                }
                {
                  correo &&
                  <TableRow key={7}>
                    <TableCell component="th" scope="row">Correo institucional</TableCell>
                    <TableCell align="left">{correo}</TableCell>
                  </TableRow>
                }
                {
                  nacionalidad &&
                  <TableRow key={8}>
                    <TableCell component="th" scope="row">Nacionalidad</TableCell>
                    <TableCell align="left">{nacionalidad.toUpperCase()}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          }
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
          </>
        </DialogActions>
      </Dialog>
    )

  }
}

class FinanciacionParticipante extends Component {
  render() {
    let { modal_fin_participante, cerrarModal, financiacionDetalle, cargando, financiacion } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modal_fin_participante} onClose={cerrarModal} >
        <AppBarModal titulo={'¡ Detalle Financiación !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {cargando ? <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} /> :
            <>
              {(financiacion && financiacion.es_financiado == '1' && !cargando) ?
                <Table>

                  <TableBody>
                    <TableRow key={1}>
                      <TableCell component="th" scope="row">Tipo de financiación</TableCell>
                      <TableCell align="left">{financiacion.tipo_financiacion.nombre}</TableCell>
                    </TableRow>
                    {financiacion.tipo_financiacion.id == TIPO_FIN_INSTITUCIONAL ?
                      <TableRow key={2}>
                        <TableCell component="th" scope="row">Dependencia</TableCell>
                        <TableCell align="left">{financiacion.dependencia.nombre}</TableCell>
                      </TableRow>
                      :
                      <>
                        <TableRow key={3}>
                          <TableCell component="th" scope="row">Fuente de financiación</TableCell>
                          <TableCell align="left">{financiacion.fuente_financiacion.nombre}</TableCell>
                        </TableRow>
                        <TableRow key={4}>
                          <TableCell component="th" scope="row">Entidad</TableCell>
                          <TableCell align="left">{financiacion.entidad}</TableCell>
                        </TableRow>
                      </>
                    }
                    <TableRow key={5}>
                      <TableCell component="th" scope="row">Valor en pesos Colombianos</TableCell>
                      <TableCell align="left">${financiacion.valor_cop}</TableCell>
                    </TableRow>
                    <TableRow key={6}>
                      <TableCell component="th" scope="row">Equivalencia en moneda extranjera</TableCell>
                      <TableCell align="left">
                        {financiacion.tipo_moneda}: {financiacion.valor_ext}
                      </TableCell>
                    </TableRow>
                    <TableRow key={8}>
                      <TableCell component="th" scope="row">Clasificación de financiación</TableCell>
                      <TableCell align="left">{financiacion.clasificacion_financiacion.nombre}</TableCell>
                    </TableRow>
                    {financiacion.clasificacion_financiacion.id == CODIGO_TIPO_CONCEPTO_OTRO &&
                      <TableRow key={9}>
                        <TableCell component="th" scope="row">Otro tipo de concepto</TableCell>
                        <TableCell align="left">{financiacion.otro_tipo_de_concepto}</TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
                :
                <TareasTerminadas mensaje={'No hay financiación para esta actividad'} marginTop='7%' imagen={emma_w} widthImg='7%' />

              }

            </>
          }

        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
          </>
        </DialogActions>
      </Dialog>
    )
  }
}


export const ModalContenedor = withStyles(styles)(ModalContenedorAll);

export { AgregarParticipante, AgregarInfoBasica, AgregarActividad, AgregarFinanciacion, AgregarParticipanteMasivo, DetalleParticipante, AgregarInstitucion, FinanciacionParticipante };
