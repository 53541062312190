import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";

// FUNCIONE GENERALES - GLOBALES
import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  List,
  Grid,
  Button,
  Icon,
} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AppBarModal from "../../general/AppBarModal";
import { BtnForm, BtnCargar } from "../../general/BotonesAccion";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import TareasTerminadas from "../../general/TareasTerminadas";
import CustomDropzone from "../../general/CustomDropzone";
import {
  obtenerPermisosValorAlt,
  obtenerValoresFiltros,
  generarFiltros,
  consulta,
  obtenerValores
} from "../../../global/js/funciones";
import AlertasSimple from "../../general/AlertasSimple";

// IMAGENES
import emma_w from "../../../global/imagenes/emma_w.png";
import emma_s from "../../../global/imagenes/emma_s.png";
import { Gif } from "@material-ui/icons";
import ConfirmarAccion from "../../general/ConfirmarAccion";

// HELPERS
import { REQUISITOS, OPCION_GRADO2, ASPIRANTE } from "./helper";

export default function MatriculasRequisitos({estado, validar_adjunto, programa, trabajoGrado, tipo, estadoAdjuntos, actualizarMensaje, validarEncuesta, setValidarEncuesta}) {

    const [requisitos, setRequisitos] = useState([]);
    const [requisitos2, setRequisitos2] = useState([]);
    const [archivos, setArchivos] = useState([]);
    let [archivo2, setarchivo2] = useState([]);
    const [openModalConfi, setOpenModalConfi] = useState(false);
    const [mensajeMatriculas, setMensajeMatriculas] = useState([
      { item: "FOTOCOPIA DE LA CEDULA DE CIUDADANÍA AMPLIADA A 150%" },
      { item: "SI REALIZO TRABAJO DE GRADO, ADJUNTAR COPIA DE LA APROBACIÓN DEL TRABAJO DE GRADO" },
      { item: "PRUEBAS SABER PRO, ANTES ECAES(ESTUDIANTES DE POSGRADO OPCIONAL)" },
      { item: "ESTUDIANTE DE DERECHO, ADJUNTAR FOTOCOPIA DE LA CARTA APROBACIÓN DE JUDICATURA" }]);
    const [cargando, setCargando] = useState(true);
    const [titulo_res, setTitulo_res] = useState('Cargando..');
    const [tipoAspirante, setAspirante] = useState(false);
    const [openModalconfig, setModalconfig] = useState(true);
    const [AdjuntosOpcionales, setAdjuntosOpcionales] = useState(false);

    useEffect(() => {
      obtenerRequisitos();
    }, []);


    const terminarCargueAdjuntos = () =>{
      setCargando(true);
      setOpenModalConfi(false);
      validar_adjunto(1, archivos)
      validar_adjunto(2, archivo2)
    }

    const submit = (archivos) => {
      let file = [];
      let files = [];
      requisitos2.map(({ codigo, nombre }) =>{
          file = archivos.filter(element => element.codigo == codigo);
          if(tipoAspirante){
            if(codigo != 'Req_Mat_Sab_Pro' && file.length <= 0){
              files.push({
                nombre: nombre,
                codigo: codigo,
              });
            }
          }else{
            if( file.length <= 0){
              files.push({
                nombre: nombre,
                codigo: codigo,
              });
            }
          } 
      });
      if(files.length > 0 ){
          actualizarMensaje({ 
            titulo: "Debe adjuntar el documento: "+files[0].nombre, 
            tipo: "info", 
            mostrar: true, 
            tiempo: 6000 
          });
        return true
      }else{
        setArchivos(archivos);
        setOpenModalConfi(true);  
        // validar_adjunto(archivos, files);
      }
    };

    const eliminarArchivo = (i) => {
      let aux = archivo2.filter((ind, iind) => iind !== i);
      setarchivo2(aux);
      actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
    }
    
    const agregarArchivos = (arc) => {
      archivo2 = archivo2.concat(arc);
      setarchivo2(archivo2)
      actualizarMensaje({ titulo: `Archivo Agregado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
    }


    const obtenerRequisitos = async () =>{
      let r = [];
      let r2 = [];
      let sw = false;
      let opcTrabajo = [];
      let opctipoAspirante = [];
      let datatrabajo = await obtenerValoresFiltros([{ 'llave': "generica", 'valor': OPCION_GRADO2 },{ 'llave': "codigo", 'valor': 'Mat_Opc_Tra_Gra'}]);
      opcTrabajo = datatrabajo.filter(valores => valores.id == trabajoGrado);

      let dataspirante = await obtenerValoresFiltros([{ 'llave': "generica", 'valor': ASPIRANTE },{ 'llave': "codigo", 'valor': 'Pos_Gra'}]);
      opctipoAspirante = dataspirante.filter(valores => valores.id == tipo);
      if(opctipoAspirante.length > 0) setAspirante(true);

      let dataprograma = await obtenerPermisosValorAlt([{'llave': 'principal', 'valor': programa },{'llave': 'secundario__generica', 'valor': REQUISITOS },{ 'llave': "codigo", 'valor': 'Req_Mat_Apr_Jud'}]); 

      let data = await obtenerPermisosValorAlt([{'llave': 'principal__codigo', 'valor': estado }, {'llave': 'secundario__generica', 'valor': REQUISITOS }, {'llave': 'secundario__valora', 'valor': 'estudiante'}]);
      data.map(({ secundario }) =>{
          if(secundario.codigo == 'Req_Mat_Cc' || secundario.codigo == 'Req_Mat_Sab_Pro') sw = true;
          if(secundario.codigo == 'Req_Mat_Tra_Gra' && opcTrabajo.length > 0) sw = true;
          if(secundario.codigo == 'Req_Mat_Apr_Jud' && dataprograma.length > 0) sw = true;
          if(sw){
            r2.push({
              nombre: secundario.nombre,
              descripcion: secundario.descripcion,
              codigo: secundario.codigo,
              archivo: secundario.archivo,
            });
          }
          r.push({
            nombre: secundario.nombre,
            descripcion: secundario.descripcion,
            codigo: secundario.codigo,
            archivo: secundario.archivo,
          });
          sw = false;
      });
      setRequisitos(r);
      setRequisitos2(r2);
      setCargando(false);
    }

    const acciones = (data, callback) => {
      const onClickCargar = (data) => callback(data);
      let cargar = (
        <BtnCargar callback={() => onClickCargar(data)} color="#00bfa5" texto="Gestionar"/>
        );
        return <div>{cargar}</div>;
    };

  const mensajeFinal = () => {
    if (cargando) {
      return <TareasTerminadas mensaje={'Cargando...'} marginTop='7%' imagen={emma_w} widthImg="7%" />
    } else if (!validarEncuesta) {
      return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          padding: "10px 0px 30px 0px",
          height: "100hv",
          backgroundColor: "white",
        }}
      >
        <TareasTerminadas mensaje={'Para dar seguimiento a tu solicitud de grado, te solicitamos amablemente que completes la siguiente encuesta. Tu colaboración es fundamental para nosotros'} marginTop='7%' imagen={emma_s} widthImg="7%" />
        <Button
          size="small"
          color='primary'
          endIcon={<OpenInNewIcon />}
          onClick={() => handleSubmitEncuesta()}
        >
          Iniciar Encuesta
        </Button>
      </div>)
    } else if (estadoAdjuntos) {
      return <TareasTerminadas mensaje={'Archivos cargados correctamente. Para finalizar haga click en Guardar.'} marginTop='7%' imagen={emma_w} widthImg="7%" />
    }
  }


  const handleSubmitEncuesta = () => {
    setValidarEncuesta(true);
    window.open("/encuesta_graduados", "_blank");
  }


    return (
      <div>
      <DialogContent  className="scroll">
        {!cargando && !estadoAdjuntos ? 
          <Paper elevation={0} square>
            <Grid container>
              <Grid item xs={12} md={12}>
                <List component="nav" arial-label="main">
                  <EnviarMultiplesArchivos
                    acciones={acciones}
                    enModal={false}
                    enviarArchivos={(archivos) => submit(archivos)}
                    limite={2}
                    mostrarMensaje={false}
                    enLista={true}
                    lista={requisitos}
                  />
                </List>        
                <AlertasSimple
                  tipo='success'
                  titulo={'¡Para cargar otros documentos que usted considere necesario clic acá!'}
                  margin = '0px'
                  callback={() => setAdjuntosOpcionales(true)}
                />
              </Grid>

              <Dialog open={AdjuntosOpcionales} fullWidth={true} maxWidth='sm' onClose={() => setAdjuntosOpcionales(false)}>
                <AppBarModal titulo='¡ Cargar Documentos !' mostrarModal={() => setAdjuntosOpcionales(false)} titulo_accion='' />
                <DialogContent className='scroll'>
                  <CustomDropzone archivos={archivo2} addFunction={agregarArchivos} deleteFunction={eliminarArchivo} actualizarMensaje={actualizarMensaje} />
                </DialogContent>
                <DialogActions>
                  <BtnForm texto="Cerrar" callback={() => setAdjuntosOpcionales(false)} />
                </DialogActions>
              </Dialog>

              <Grid item xs={12}>
                {/* {this.configStepContent()} */}
                <AlertasSimple
                  tipo="info"
                  titulo={"Estimado Estudiante, tener en cuenta: "}
                  lista={mensajeMatriculas}
                  margin="0px"
                />
              </Grid>
            </Grid>
          </Paper>
            : mensajeFinal()
        }
        {
            !cargando && !estadoAdjuntos && 
            <DialogActions>
                <BtnForm texto="TERMINAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
            </DialogActions>
        }
      </DialogContent>
      <ConfirmarAccion
            dato={{ id: 0 }}
            titulo={' ¿ Estas seguro ?'}
            mensaje1={'Se guardarán los archivos adjuntados, '}
            ejecutarAccion={() => terminarCargueAdjuntos()}
            modalConAccion={openModalConfi}
            mostrarModalConfAccion={(resp) => setOpenModalConfi(resp)}
        />
    </div>
    );
  
}
