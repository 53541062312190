import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import BuscarSelect from '../../general/BuscarSelect';
import AlertasSimple from '../../general/AlertasSimple';
import { EPS_OTRO } from '../helper';

export default function InscripcionAgregarMiContacto({ props_c }) {

  let {
    departamentos,
    departamento,
    setDepartamento,
    ciudades,
    ciudad,
    setCiudad,
    estratos,
    estrato,
    setEstrato,
    onChange,
    barrio,
    nombre_madre,
    nombre_padre,
    celular_madre,
    celular_padre,
    autorizacion_padre,
    direccion,
    opcionesPoseeEps,
    setPoseeEps,
    poseeEps,
    opcionesEps,
    nombreEps,
    setNombreEps,
    contactoEmergencia,
    celularEmergencia,
    nombreEpsOtro,
  } = props_c;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos Finales
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BuscarSelect datos={departamentos} cambiarEstado={(value) => setDepartamento(value)} valor={departamento} id='select-departamento' nombre='¿En que departamento vives?' placeholder='Seleccione Opción' />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect datos={ciudades} cambiarEstado={(value) => setCiudad(value)} valor={ciudad} id='select-ciudad' nombre='¿En que ciudad o municipio vives?' placeholder='Seleccione Opción' />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField value={barrio.toUpperCase()} onChange={onChange} name="barrio" required id="barrio" label="¿En que barrio vives?" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField value={direccion} onChange={onChange} name="direccion" required id="direccion" label="¿Cual es tu dirección de residencia?" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect datos={estratos} cambiarEstado={(value) => setEstrato(value)} valor={estrato} id='select-estrato' nombre='¿Cual es el estrato de tu vivienda?' placeholder='Seleccione Opción' />
        </Grid>
      
        <Grid item xs={12}>
          <BuscarSelect datos={opcionesPoseeEps} cambiarEstado={(value) => setPoseeEps(value)} valor={poseeEps} id='select-eps' nombre='¿Posee EPS?' placeholder='Seleccione Opción' />
        </Grid>
        {poseeEps && poseeEps.value === 'si' && 
        <Grid item xs={12}>
          <BuscarSelect datos={opcionesEps} cambiarEstado={(value) => setNombreEps(value)} valor={nombreEps} id='select-nombre-eps' nombre='¿Cual es el nombre de la EPS?' placeholder='Seleccione Opción' />
        </Grid>
        }

        {poseeEps && poseeEps.value == 'si' && nombreEps.value === EPS_OTRO && 
        <Grid item xs={12}>
            <TextField
              value={nombreEpsOtro.toUpperCase()}
              required
              id="nombre_eps"
              name="nombreEpsOtro"
              label="Escriba nombre de la EPS"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
        </Grid>
        }

        <Grid item xs={12}>
            <TextField
              value={nombre_madre.toUpperCase()}
              required
              id="nombre_madre"
              name="nombre_madre"
              label="¿Cuál es el nombre de la Madre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='number'
              value={celular_madre}
              required
              id="celular_madre"
              name="celular_madre"
              label="¿Cuál es el número de celular de tu madre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              value={nombre_padre.toUpperCase()}
              required
              id="nombre_padre"
              name="nombre_padre"
              label="¿Cuál es el nombre del Padre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={celular_padre}
              required
              type='number'
              id="celular_padre"
              name="celular_padre"
              label="¿Cuál es el número de celular de su Padre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              value={contactoEmergencia.toUpperCase()}
              required
              id="contacto_emergencia"
              name="contactoEmergencia"
              label="¿Nombre de contacto de emergencia?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={celularEmergencia}
              required
              type='number'
              id="celular_emergencia"
              name="celularEmergencia"
              label="¿Cuál es el número de celular de emergencia?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <AlertasSimple
          tipo="danger"
          titulo={<><p style={{ textAlign: 'justify', margin: '0', fontSize: '.9rem' }}>
            Estimado usuario al presionar el boton Terminar esta aceptando los terminos de <b>AUTORIZACION PADRES</b> de la Corporacion Universitaria de la Costa CUC.</p>
            <p>Para ver los terminos y condiciones click sobre este mensaje</p></>}
          callback={() =>autorizacion_padre(true)}
          />
      </Grid>
    </React.Fragment>
  );
}
