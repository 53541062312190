import React from "react";
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment'


export function InscripcionDetalleDatos({ inscripcion }) {
    let { estudiante, celular1, telefono1, fecha_registro, estado_actual, grupo, discapacidad, gestor, codigo_pruebas, puntaje_pruebas, puntaje_sisben, sisben } = inscripcion;
    let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
    return (
        <Table>
            <TableBody>
                <TableRow key={1}>
                    <TableCell component="th" scope="row">Nombre Completo</TableCell>
                    <TableCell align="left">{`${estudiante.primer_nombre} ${estudiante.segundo_nombre ? estudiante.segundo_nombre : ''} ${estudiante.primer_apellido} ${estudiante.segundo_apellido ? estudiante.segundo_apellido : ''}`}</TableCell>
                </TableRow>
                <TableRow key={2}>
                    <TableCell component="th" scope="row">Correo</TableCell>
                    <TableCell align="left">{estudiante.correo}</TableCell>
                </TableRow>
                <TableRow key={3}>
                    <TableCell component="th" scope="row">Tipo Identificación</TableCell>
                    <TableCell align="left">{estudiante.tipo_identificacion.nombre}</TableCell>
                </TableRow>
                <TableRow key={4}>
                    <TableCell component="th" scope="row">Identificación</TableCell>
                    <TableCell align="left">{estudiante.identificacion}</TableCell>
                </TableRow>
                {/* <TableRow key={5}>
                    <TableCell component="th" scope="row">Usuario</TableCell>
                    <TableCell align="left">{estudiante.usuario}</TableCell>
                </TableRow> */}
                <TableRow key={6}>
                    <TableCell component="th" scope="row">Telefono - Celular</TableCell>
                    <TableCell align="left">{`${telefono1} - ${celular1}`}</TableCell>
                </TableRow>
                {codigo_pruebas ?
                    <TableRow key={13}>
                        <TableCell component="th" scope="row">Código Registro Pruebas Saber 11</TableCell>
                        <TableCell align="left">{codigo_pruebas}</TableCell>
                    </TableRow>
                    : <TableRow key={13}></TableRow>
                }
                { puntaje_pruebas ?
                    <TableRow key={14}>
                        <TableCell component="th" scope="row">Puntaje Pruebas Saber 11</TableCell>
                        <TableCell align="left">{puntaje_pruebas}</TableCell>
                    </TableRow>
                    : <TableRow key={14}></TableRow>
                }
                { sisben ?
                    <TableRow key={16}>
                        <TableCell component="th" scope="row">Sisben</TableCell>
                        <TableCell align="left">{sisben.toUpperCase()}</TableCell>
                    </TableRow>
                    : <TableRow key={16}></TableRow>
                }
                { sisben == 'si' &&  puntaje_sisben ?
                    <TableRow key={15}>
                        <TableCell component="th" scope="row">Puntaje Sisben</TableCell>
                        <TableCell align="left">{puntaje_sisben}</TableCell>
                    </TableRow>
                    : <TableRow key={15}></TableRow>
                }
                {grupo ?
                    <TableRow key={7}>
                        <TableCell component="th" scope="row">Comunidad Especial</TableCell>
                        <TableCell align="left">{grupo.nombre}</TableCell>
                    </TableRow>
                    : <TableRow key={7}></TableRow>
                }
                {discapacidad ?
                    <TableRow key={8}>
                        <TableCell component="th" scope="row">Discapacidad</TableCell>
                        <TableCell align="left">{discapacidad.nombre}</TableCell>
                    </TableRow>
                    : <TableRow key={8}></TableRow>
                }
                {gestor ?
                    <TableRow key={9}>
                        <TableCell component="th" scope="row">Gestor</TableCell>
                        <TableCell align="left">{`${gestor.primer_nombre} ${gestor.primer_apellido} ${gestor.segundo_apellido}`}</TableCell>
                    </TableRow>
                    : <TableRow key={9}></TableRow>
                }
                {gestor ?
                    <TableRow key={10}>
                        <TableCell component="th" scope="row">Correo Gestor</TableCell>
                        <TableCell align="left">{gestor.correo}</TableCell>
                    </TableRow>
                    : <TableRow key={10}></TableRow>
                }
                <TableRow key={11}>
                    <TableCell component="th" scope="row">Estado Actual</TableCell>
                    <TableCell align="left">{estado_actual.nombre}</TableCell>
                </TableRow>
                <TableRow key={12}>
                    <TableCell component="th" scope="row">Fecha Registro</TableCell>
                    <TableCell align="left">{fecha}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )

}

export function InscripcionDetalleContacto({ inscripcion }) {
    let { departamento, ciudad, estrato, barrio, direccion, nombre_eps,
        nombre_otro_eps, } = inscripcion;
    return (
        <Table >
            <TableBody>
                <TableRow key={1}>
                    <TableCell component="th" scope="row">Departamento</TableCell>
                    <TableCell align="left">{departamento.nombre}</TableCell>
                </TableRow>
                <TableRow key={2}>
                    <TableCell component="th" scope="row">Ciudad/Municipio</TableCell>
                    <TableCell align="left">{ciudad.nombre}</TableCell>
                </TableRow>
                <TableRow key={3}>
                    <TableCell component="th" scope="row">Barrio</TableCell>
                    <TableCell align="left">{barrio}</TableCell>
                </TableRow>
                <TableRow key={4}>
                    <TableCell component="th" scope="row">Dirección</TableCell>
                    <TableCell align="left">{direccion}</TableCell>
                </TableRow>
                <TableRow key={5}>
                    <TableCell component="th" scope="row">Estrato</TableCell>
                    <TableCell align="left">{estrato.nombre}</TableCell>
                </TableRow>
                <TableRow key={6}>
                    <TableCell component="th" scope="row">Nombre EPS</TableCell>
                    <TableCell align="left">{nombre_eps?.nombre ?? "Nunguno"}</TableCell>
                </TableRow>
                {nombre_otro_eps &&
                    <TableRow key={7}>
                        <TableCell component="th" scope="row">Nombre Otro EPS</TableCell>
                        <TableCell align="left">{nombre_otro_eps}</TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    )
}
export function InscripcionDetalleCarrera({ inscripcion }) {
    let { generacion, carrera, conocio, } = inscripcion;
    if(!carrera){
        carrera={'nombre':''}
    }
    if(!conocio){
        conocio={'nombre':'EDU.co'}
    }
    if( generacion === 'otro') generacion='ESPERANDO RESULTADO'
    return (
        <Table >
            <TableBody>
                <TableRow key={1}>
                    <TableCell component="th" scope="row">Carrera</TableCell>
                    <TableCell align="left">{carrera.nombre}</TableCell>
                </TableRow>
                <TableRow key={2}>
                    <TableCell component="th" scope="row">Conoció</TableCell>
                    <TableCell align="left">{conocio.nombre}</TableCell>
                </TableRow>
                {generacion ?
                    <TableRow key={3}>
                        <TableCell component="th" scope="row">Generación E</TableCell>
                        <TableCell align="left">{generacion.toUpperCase()}</TableCell>
                    </TableRow>
                    : <TableRow key={10}></TableRow>
                }
            </TableBody>
        </Table>
    )
}
