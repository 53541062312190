import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, FormControl, Grid, Step, StepLabel, Stepper, InputLabel, Select, MenuItem, TextField, List, Paper, FormHelperText, withStyles, IconButton, InputAdornment, FormControlLabel, Checkbox, TableCell, TableRow, TableBody, TableHead, Table, TableContainer, ListItemText, Link, ListItem, makeStyles, Button, Fab, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import BuscarPersona from '../../general/BuscarPersona';
import Detalle from '../../general/Detalle';
import emma_w from '../../../global/imagenes/emma_w.png';
import ListarDatos from '../../general/ListarDatos';
import { BtnCancelar, BtnCargar, BtnDescargar, BtnDetalle, BtnEditar, BtnForm } from '../../general/BotonesAccion';
import { api, crear_form_data, formulario, getError, mostrarError, obtenerMisDatos, obtenerPermisosValorAlt, obtenerValores, obtenerValoresFiltros, validateInputs } from '../../../global/js/funciones';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import { AgregarActividad, AgregarFinanciacion, AgregarInfoBasica, AgregarInstitucion, AgregarParticipante, AgregarParticipanteMasivo, DetalleParticipante, ModalContenedor } from './forms/Forms';
import { CODIGO_CURSO_CORTO, CODIGO_CURSO_CORTO_PROFE, CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD, CODIGO_TIPO_CONCEPTO_OTRO, DEPARTAMENTO_RESPONSABLE, DEPENDENCIAS_FINANCIACIONES_MOVILIDAD, FACULTAD_POSGRADOS_PARTICI, FACULTAD_RESPONSABLE, FUENTES_FINANCIACIONES_MOVILIDAD, MASIVOS_INTER, MODALIDADES_VIRTUALES, OTRA_PLATAFORMA, OTRO_SUBTIPO_ACTIVIDAD, OTRO_TIPO_ACTIVIDAD, PROGRAMAS, REQUISITOS_MOV, SUBTIPOS_ACTIVIDADES_MOVILIDAD, SUBTIPO_ACTI_ACADEMICO, SUBTIPO_ACTI_EMPRESARIAL, TIPOS_ACTIVIDADES_MOVILIDAD, TIPOS_CONCEPTOS_MOV, TIPOS_FINANCIACIONES_MOVILIDAD, TIPOS_FORMS_MOVILIDAD, TIPOS_IDENTICACION, TIPOS_MODALIDADES, TIPOS_MOVILIDADES, TIPOS_MOV_ENTRANTES, TIPOS_MOV_SALIENTES, TIPOS_NIVELES_EDU, TIPOS_PARTICIPANTES, TIPOS_PLATAFORMAS, TIPO_FIN_INSTITUCIONAL, TIPO_FIN_INTERNACIONAL, TIPO_FIN_NACIONAL, TIPO_MOVILIDADES_PROFESOR, TIPO_MOV_DOCENTE, TIPO_MOV_ESTUDIANTE, TIPO_PARTICIPANTE_ADMIN, TIPO_PARTICIPANTE_DOC, TIPO_PARTICIPANTE_ESTU, TIPO_RESPONSABLE, UNIDAD_ADMIN_RESPONSABLE } from './helper';
import AddIcon from '@material-ui/icons/Add';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import { CURRENCIES } from '../currencies';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { es } from 'date-fns/locale'
import InputFile from '../../general/InputFile';
import DeleteIcon from '@material-ui/icons/Delete';
import ConvocatoriasBuscarConvenio from '../convocatorias/ConvocatoriasBuscarConvenio';
import EnviarMultiplesArchivos from '../../general/EnviarMultiplesArchivos';

const configStep = ['Actividad', 'Participantes', 'Evidencias'];

const styles = {
  // flexCenter: {
  //   textAlign: "center",
  //   marginTop: "5px"
  // },
  colorEmmma: {
    color: "#c79e32",
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: "#c79e32",
      transform: "scale(1.3)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  }
}


class RegistroCrear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      mostrarForm: false,
      cargando: false,
      cargandoForm: false,
      cargandoContenedor: false,
      enviando: false,
      tit_cargando: 'Cargando..',
      activeStep: 0,
      selectAll: false,
      datosParticipante: {
        primer_nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        tipo_identificacion: '',
        identificacion: '',
        nacionalidad: '',
        celular: '',
        correo_personal: '',
        correo: '',
        indicativo_celular: ''
      },
      participantes: [],
      participantesSelect: [],
      participanteDetalle: {},
      errores: [],
      participante_quitar: 0,
      participante_tipo: null,
      error: false,
      ident_tipo_movilidad: null,

      // INFO BASICA
      tipo_movilidad: '',
      tipo_modalidad: '',
      plataforma: '',
      otra_plataforma: '',

      // ACTIVIDAD
      tipo_actividad: '',
      otro_tipo_actividad: '',
      subtipo_actividad: '',
      otro_subtipo_actividad: '',
      institucion_seleccionada: '',
      // horas: '',
      fecha_inicio: null,
      fecha_fin: null,
      responsable_actividad: [],
      dpto_responsable: [],
      facultad_responsable: [],
      administrativos_responsable: [],
      titulo_tema: '',
      descripcion_actividad: '',
      resultados_descripcion: '',
      info_complementaria: '',
      info_complementaria_f: '',
      instituciones: [],
      departamento_docente: '',

      // FINANCIACIÓN
      es_financiado: '',
      tipo_financiacion: '',
      dependencia: '',
      fuente_financiacion: '',
      entidad: '',
      valor_cop: '',
      tipo_moneda: '',
      valor_ext: '',
      clasificacion_de_financiacion: '',
      otro_tipo_de_concepto: '',
      comprobante_financiacion: '',

      // INSTITUCION
      institucion_participante: { id: 0, institucion: '', pais: '', ciudad: '' },
      tipo_participante: null,
      dpto_participante: '',
      programa_participante: '',
      facultad_participante: '',
      nivel_participante: '',


      // CONSULTA GENERAL
      tipos_movilidades_all: [],

      // CONSULTAS
      tipos_forms: [],
      tipos_identificacion: [],
      tipos_movilidades: [],
      tipos_modalidades: [],
      plataformas: [],
      tipos_actividades: [],
      subtipos_actividades: [],
      tipos_responsables: [],
      departamentos_resp: [],
      facultades_resp: [],
      administrativos_resp: [],
      tipos_financiaciones: [],
      dependencias: [],
      fuentes_financiacion: [],
      monedas: [],
      tipos_conceptos: [],
      tipos_participantes: [],
      dptos_academicos: [],
      niveles_educativos: [],
      programas_receptores: [],
      programas_receptores_docente: [],
      evidencias: [],
      masivos: [],

      // DATOS MULTIPLES
      info_inicial_participantes: [],
      actividad_participantes: [],
      financiacion_participantes: [],
      institucion_participantes: [],




      // MODALES
      modalAddParticipante: false,
      modalDelParticipante: false,
      modalContenedor: false,
      modalAddInfoBasica: false,
      modalAddActividad: false,
      modalAddFinanciacion: false,
      modalAddInstitucion: false,
      modalAddEvidencia: false,
      modalAddParticipanteMasivo: false,
      modalDetParticipante: false,
      modal_buscar_institucion: false,
      modal_quitar_institucion: false,
      modal_elimi_institucion: false,
      modalAddParticipantExt: false,
    }
    // ARCHIVOS
    this.fileInput = React.createRef();
  }

  async componentDidMount() {
    let tipos_movilidades_all = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_MOVILIDADES }, { 'llave': 'estado', 'valor': 1 }]);
    let tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_IDENTICACION }, { 'llave': 'estado', 'valor': 1 }]);
    let masivos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MASIVOS_INTER }, { 'llave': 'estado', 'valor': 1 }]);
    this.setState({ tipos_movilidades_all, masivos, tipos_identificacion });
  }

  async componentDidUpdate(prevProps, prevState) {
    let { modalAddParticipante, modalAddInfoBasica, modalContenedor, participantes, es_financiado, tipo_financiacion, mostrarForm, tipo_actividad, subtipo_actividad, tipo_movilidad, modalAddInstitucion, participantesSelect, info_inicial_participantes, tipo_participante, facultad_participante, nivel_participante, niveles_educativos, dpto_participante, activeStep } = this.state
    let { modalAddRegistro, tipo_solicitud } = this.props

    // MOSTRAR FORMULARIO DE ACTIVIDAD ✅
    if (prevState.mostrarForm !== mostrarForm) {
      if (modalAddRegistro && mostrarForm) {
        this.setState({ cargandoForm: true });
        // let tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_IDENTICACION }, { 'llave': 'estado', 'valor': 1 }]);
        let tipos_actividades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_ACTIVIDADES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': '1' }]);
        let tipos_responsables = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        let administrativos_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIDAD_ADMIN_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        let departamentos_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPARTAMENTO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorg', 'valor': '1' }]);
        let facultades_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FACULTAD_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({ tipos_actividades, cargandoForm: false, tipos_responsables, administrativos_resp, departamentos_resp, facultades_resp});
      }
    }

    // TIPO DE ACTIVIDAD
    // LIMPIAR MOVILIDAD Y MODALIDAD???????
    if (prevState.tipo_actividad !== tipo_actividad) {
      if (tipo_actividad) {
        this.setState({ cargandoForm: true, otro_tipo_actividad: '', subtipo_actividad: '', otro_subtipo_actividad: '' });
        // OBTENER SUBTIPOS DE ACTIVIDADES DE ACUERDO AL TIPO DE ACTIVIDAD
        let subtipos_actividades = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': SUBTIPOS_ACTIVIDADES_MOVILIDAD }, { 'llave': 'secundario__valora', 'valor': '1' }]);
        // ⚠️ secundario valora es para las subactividades que se deben mostrar en el formulario

        // ⚠️ EXISTEN ACTIVIDADES QUE NO TIENEN SUBTIPOS, SIN EMBARGO SE LE RELACIONA UNA SUBACTIVIDAD QUE (NO APLICA) PARA TENER LOS PERMISOS DE MOVILIDAD Y MODALIDAD
        const subtipo_actividad = subtipos_actividades.some(item => item.secundario.codigo === CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD) ? CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD : '';
        this.setState({ subtipos_actividades, cargandoForm: false, otro_subtipo_actividad: '', subtipo_actividad });
      }
    }

    // SUBTIPO DE ACTIVIDAD ✅
    if (prevState.subtipo_actividad !== subtipo_actividad && subtipo_actividad) {
      this.setState({ cargandoForm: true });

      let modalidadActividad, modalidadSubActividad, tipo_movilidad_act, tipo_movilidad_subact;

      // ⚠️ SI EL TIPO DE ACTIVIDAD ES CURSO CORTO Y EL SUBTIPO ES EMPRESARIAL, SE DEBE MOSTRAR LAS MODALIDADES DE PROFESOR
      // SI NO, SE DEBEN MOSTRAR LAS MODALIDADES DE ACUERDO AL TIPO DE ACTIVIDAD Y SUBTIPO DE ACTIVIDAD
      if (tipo_actividad === CODIGO_CURSO_CORTO && subtipo_actividad === SUBTIPO_ACTI_EMPRESARIAL) {
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO_PROFE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        tipo_movilidad_act = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO_PROFE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);
      } else {
        // ⚠️ CONSULTA DE MODALIDADES DE ACUERDO AL TIPO DE ACTIVIDAD
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        // ⚠️ CONSULTA DE TIPOS DE MOVILIDADES DE ACUERDO AL TIPO DE ACTIVIDAD
        tipo_movilidad_act = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);
      }

      // ⚠️ CONSULTA DE MODALIDADES DE ACUERDO AL SUBTIPO DE ACTIVIDAD
      modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      // ⚠️ CONSULTA DE TIPOS DE MOVILIDADES DE ACUERDO AL SUBTIPO DE ACTIVIDAD
      tipo_movilidad_subact = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);

      // ⚠️ CRUCE ENTRE MODALIDAD DE ACTIVIDAD Y MODALIDAD DE SUBACTIVIDAD PARA OBTENER LAS MODALIDADES QUE SE DEBEN MOSTRAR
      const coincidencias_modalidad = modalidadActividad.filter(obj1 => modalidadSubActividad.some(obj2 => obj1.secundario.id === obj2.secundario.id));
      // ⚠️ CRUCE ENTRE TIPO DE MOVILIDAD DE ACTIVIDAD Y TIPO DE MOVILIDAD DE SUBACTIVIDAD PARA OBTENER LOS TIPOS DE MOVILIDADES QUE SE DEBEN MOSTRAR
      const coincidencias_movilidad = tipo_movilidad_act.filter(obj1 => tipo_movilidad_subact.some(obj2 => obj1.secundario.id === obj2.secundario.id));

      this.setState({ tipos_modalidades: coincidencias_modalidad, tipos_movilidades: coincidencias_movilidad, cargandoForm: false });
    }

    // MODAL CONTENEDOR ✅
    if (prevState.modalContenedor !== modalContenedor) {
      if (modalContenedor) {
        this.setState({ cargandoContenedor: true });
        let tipos_forms = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_FORMS_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({ tipos_forms, cargandoContenedor: false });
      }
      if (!modalContenedor) this.setState({ tipos_forms: [] });
    }

    // MODAL ADD PARTICIPANTE ✅
    if (prevState.modalAddParticipante !== modalAddParticipante) {
      // if (modalAddParticipante) {
      //   this.setState({ cargandoForm: true });
      //   let tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_IDENTICACION }, { 'llave': 'estado', 'valor': 1 }]);
      //   this.setState({ tipos_identificacion, cargandoForm: false })
      // }
      if (!modalAddParticipante) this.resetForm('participante');
    }

    // if (prevState.participantes !== participantes) {
    //   let ultimoObjeto = participantes[participantes.length - 1];
    //   if (ultimoObjeto !== undefined) {
    //     console.log(ultimoObjeto)
    //     if (ultimoObjeto.id !== participantes.length) {
    //       participantes.forEach((participante, index) => { participante.id = index + 1 });
    //       this.setState({ participantes });
    //     }
    //   }
    // }

    if (prevState.modalAddInfoBasica !== modalAddInfoBasica) {
      if (modalAddInfoBasica) {
        this.setState({ cargandoForm: true });
        let tipos_plataformas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_PLATAFORMAS }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({ plataformas: tipos_plataformas, cargandoForm: false });
      }
      if (!modalAddInfoBasica) this.resetForm('info_basica');
    }


    if (prevState.es_financiado !== es_financiado) {
      if (es_financiado === '1') {
        this.setState({ cargandoForm: true });
        let tipos_financiaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
        let tipos_conceptos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_CONCEPTOS_MOV }, { 'llave': 'estado', 'valor': 1 }]);
        let monedas = CURRENCIES
        this.setState({ tipos_financiaciones, monedas, tipos_conceptos, cargandoForm: false });
      }
    }

    if (prevState.tipo_financiacion !== tipo_financiacion) {
      if (tipo_financiacion == TIPO_FIN_NACIONAL) {
        this.setState({ cargandoForm: true });
        let fuentes_financiacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'nacional' }]);
        this.setState({ fuentes_financiacion, cargandoForm: false });
      } else if (tipo_financiacion == TIPO_FIN_INTERNACIONAL) {
        this.setState({ cargandoForm: true });
        let fuentes_financiacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'internacional' }]);
        this.setState({ fuentes_financiacion, cargandoForm: false });
      } else {
        this.setState({ cargandoForm: true });
        let dependencias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPENDENCIAS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({ dependencias, cargandoForm: false });
      }
    }

    if (prevState.tipo_movilidad !== tipo_movilidad && tipo_movilidad) {
      let modalidadActividad, modalidadSubActividad;
      this.setState({ cargandoForm: true });
      if (tipo_actividad === CODIGO_CURSO_CORTO && subtipo_actividad === SUBTIPO_ACTI_ACADEMICO && TIPO_MOVILIDADES_PROFESOR.includes(tipo_movilidad)) {
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'Academico_subtipo_actividad_profesor' }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      } else {
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      }
      const coincidencias = modalidadActividad.filter(obj1 => modalidadSubActividad.some(obj2 => obj1.secundario.id === obj2.secundario.id));
      this.setState({ tipos_modalidades: coincidencias, cargandoForm: false });
    }

    if (prevState.modalAddInstitucion !== modalAddInstitucion) {
      if (modalAddInstitucion) {
        let error = false;
        this.setState({ cargandoForm: true, error: false, participante_tipo: null, tipos_participantes: [], tipo_participante: null });
        const participantesSeleccionados = participantesSelect.map(participante => participante.identificacion);
        console.log(participantesSeleccionados)
        const informacionParticipantesSeleccionados = info_inicial_participantes.filter(participante => {
          return participante.participantes.some(identificacion => participantesSeleccionados.includes(identificacion));
        });
        console.log(informacionParticipantesSeleccionados)

        if (informacionParticipantesSeleccionados.length === 0) {
          console.log('error')
          error = true;
          this.setState({ error: true })
        }

        if (!error) {
          let tiposMovilidad = [];
          if (informacionParticipantesSeleccionados.length > 0) {
            tiposMovilidad = informacionParticipantesSeleccionados.map(participante => participante.tipo_movilidad);
          }

          // Verificar si todos los tipos de movilidad son iguales
          const sonIguales = tiposMovilidad.every(tipo => tipo === tiposMovilidad[0]);

          // Verificar si todos los tipos de movilidad corresponden a estudiantes
          const sonEstudiantes = tiposMovilidad.length > 0 ? tiposMovilidad.every(tipo => TIPO_MOV_ESTUDIANTE.includes(tipo)) : false;

          // Verificar si todos los tipos de movilidad corresponden a docentes
          const sonDocentes = tiposMovilidad.length > 0 ? tiposMovilidad.every(tipo => TIPO_MOV_DOCENTE.includes(tipo)) : false;

          // Verificar si todos los tipos de movilidad corresponden a salientes
          const sonSalientes = tiposMovilidad.length > 0 ? tiposMovilidad.every(tipo => TIPOS_MOV_SALIENTES.includes(tipo)) : false;

          // Verificar si todos los tipos de movilidad corresponden a entrantes
          const sonEntrantes = tiposMovilidad.length > 0 ? tiposMovilidad.every(tipo => TIPOS_MOV_ENTRANTES.includes(tipo)) : false;

          let tipos_participantes = [];
          if (sonEstudiantes) {
            tipos_participantes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_PARTICIPANTES }, { 'llave': 'valora', 'valor': 1 }, { 'llave': 'estado', 'valor': 1 }]);
            this.setState({ participante_tipo: true, tipos_participantes, tipo_participante: tipos_participantes[0].id });
          } else if (sonDocentes) {
            tipos_participantes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_PARTICIPANTES }, { 'llave': 'valora', 'valor': 0 }, { 'llave': 'estado', 'valor': 1 }]);
            this.setState({ participante_tipo: false, tipos_participantes });
          } else {
            this.setState({ participante_tipo: 'distinto' });
          }

          if (sonSalientes) this.setState({ ident_tipo_movilidad: 'saliente' })
          else if (sonEntrantes) this.setState({ ident_tipo_movilidad: 'entrante' })
          else this.setState({ ident_tipo_movilidad: 'distinto' })
        }

        this.setState({ cargandoForm: false });
      }
      if (!modalAddInstitucion) this.resetForm('institucion');
    }

    if (prevState.tipo_participante !== tipo_participante) {
      if (tipo_participante) {
        this.setState({ cargandoForm: true });
        this.setState({ dpto_participante: '', programa_participante: '', facultad_participante: '', facultades_resp: [], dptos_academicos: [], dependencias: [] });

        if (tipo_participante == TIPO_PARTICIPANTE_ESTU) {
          let facultades_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FACULTAD_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
          this.setState({ facultades_resp });
        }


        if (tipo_participante == TIPO_PARTICIPANTE_DOC) {
          let dptos_academicos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPARTAMENTO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'docente' }]);
          this.setState({ dptos_academicos });

        } else if (tipo_participante == TIPO_PARTICIPANTE_ADMIN) {
          let dptos_academicos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPARTAMENTO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'administrativo' }]);
          let dependencias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPENDENCIAS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
          this.setState({ dptos_academicos, dependencias });
        }
        this.setState({ cargandoForm: false });
      }
    }

    if (prevState.facultad_participante !== facultad_participante) {
      if (facultad_participante) {
        this.setState({ cargandoForm: true, nivel_participante: '' });
        if (facultad_participante == FACULTAD_POSGRADOS_PARTICI) {
          let niveles_educativos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_NIVELES_EDU }, { 'llave': 'valora', 'valor': 'posgrado' }, { 'llave': 'estado', 'valor': 1 }]);
          this.setState({ niveles_educativos });
        } else {
          let niveles_educativos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_NIVELES_EDU }, { 'llave': 'valora', 'valor': 'pregrado' }, { 'llave': 'estado', 'valor': 1 }]);
          this.setState({ niveles_educativos });
        }
        this.setState({ cargandoForm: false });
      }
    }

    if (prevState.facultad_participante !== facultad_participante || prevState.nivel_participante !== nivel_participante) {
      if (facultad_participante && nivel_participante) {
        this.setState({ cargandoForm: true });
        let permisos_programas = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': facultad_participante }, { 'llave': 'secundario__generica', 'valor': PROGRAMAS }]);
        let programas_receptores = [];
        let nivel = niveles_educativos.find(({ id }) => id == nivel_participante).nombre.toLowerCase();
        if (nivel && nivel == 'pregrado') programas_receptores = permisos_programas.filter(programa => programa.secundario.valorc == nivel).map(programa => programa.secundario); // Trae programas asociados a la facultad y al nivel educativo seleccionado (pregrado).
        else programas_receptores = permisos_programas.filter(programa => programa.secundario.valord == nivel).map(programa => programa.secundario); // Trae programas asociados a la facultad y al nivel educativo seleccionado (posgrados).
        this.setState({ cargandoForm: false, programas_receptores });
      }
    }

    if (prevState.dpto_participante !== dpto_participante) {
      if (dpto_participante) {
        if (tipo_participante == TIPO_PARTICIPANTE_DOC) {
          this.setState({ cargandoForm: true });
          let programas_receptores_docente = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': dpto_participante }, { 'llave': 'secundario__generica', 'valor': PROGRAMAS }]);
          this.setState({ programas_receptores_docente, cargandoForm: false });
        }
      }
    }


    if (prevState.activeStep !== activeStep) {
      if (activeStep) {
        if (activeStep === 2) {
          this.setState({ cargandoForm: true });
          let evidencias = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo_solicitud }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': REQUISITOS_MOV }]);
          console.log(evidencias)
          this.setState({ evidencias, cargandoForm: false });
        }
      }
    }

  }


  actualizarEstado = (nombreEstado, valor) => {
    this.setState(prevState => ({
      [nombreEstado]: typeof valor === 'object' && !Array.isArray(valor)
        ? { ...prevState[nombreEstado], ...valor }
        : valor
    }));
  };

  // actualizarEstado = (nombreEstado, valor) => {
  //   this.setState(prevState => ({
  //     [nombreEstado]: typeof valor === 'object'
  //       ? { ...prevState[nombreEstado], ...valor }
  //       : valor
  //   }));
  // };


  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  onChangeFile = ({ target }, f) => {
    let { value, name } = target;
    this.setState({ [name]: value, info_complementaria_f: f });
  }

  onChangeParticipante = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      datosParticipante: {
        ...prevState.datosParticipante,
        [name]: value
      }
    }));
  }

  onChangeDate = (nombre, date) => {
    this.setState({ [nombre]: date });
  }

  handleToggle = (identificacion, id) => {
    const { participantesSelect } = this.state;
    const currentIndex = participantesSelect.findIndex(participante => participante.identificacion === identificacion);

    if (currentIndex === -1) this.setState(prevState => ({ participantesSelect: [...prevState.participantesSelect, { identificacion }] }));
    else this.setState(prevState => ({ participantesSelect: prevState.participantesSelect.filter(participante => !(participante.identificacion === identificacion)) }));
  };

  isParticipanteSelected(identificacion, id) {
    const { participantesSelect } = this.state;
    return participantesSelect.some(participante => participante.identificacion === identificacion);
  }

  handleSelectAllToggle = () => {
    const { participantes, selectAll } = this.state;
    const allParticipants = participantes.map(participante => ({ identificacion: participante.identificacion }));
    this.setState({ selectAll: !selectAll, participantesSelect: selectAll ? [] : allParticipants });
  };

  seleccionarInstitucionActividad = ({ id, institucion, codigo_convenio }) => {
    const { instituciones, errores } = this.state;
    this.setState({
      instituciones: instituciones.concat({ id, institucion, codigo_convenio }),
      errores: errores.length > 0 ? errores.filter(er => er.llave !== 'instituciones') : errores
    });
  }

  renderButton = (accion, nombre) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={accion}
        style={{
          fontSize: '0.8em',
          borderRadius: '1.5em',
          padding: '0.6em 1.2em',
          minWidth: 'auto',
          minHeight: 'auto',
          color: 'white'
        }}
      >
        {nombre}
        {/* <AttachMoneyIcon style={{ fontSize: 'small' }} /> */}
      </Button>
      // <AddCircleOutlineIcon color='primary' fontSize='default' style={{ cursor: 'pointer',  }} onClick={accion} />

    )
  }

  quitarParticipante = () => {
    let { participantes, participante_quitar, info_inicial_participantes, financiacion_participantes, institucion_participantes } = this.state;
    let { actualizarMensaje } = this.props;

    // Filtrar participante a eliminar de la lista de participantes
    let participantesNew = participantes.filter(({ identificacion }) => identificacion !== participante_quitar.identificacion);

    // Filtrar participante a eliminar de info_inicial_participantes
    let infoInicialParticipantesNew = info_inicial_participantes.map(item => {
      const participantesFiltrados = item.participantes.filter(participante => participante !== participante_quitar.identificacion);
      // Si el participante a eliminar es el único registrado, eliminamos toda la entrada
      if (participantesFiltrados.length === 0) {
        return null; // No retornamos este item
      } else {
        return { ...item, participantes: participantesFiltrados };
      }
    }).filter(Boolean); // Filtramos los elementos nulos

    // Filtrar participante a eliminar de financiacion_participantes
    let financiacionParticipantesNew = financiacion_participantes.map(item => {
      const participantesFiltrados = item.participantes.filter(participante => participante !== participante_quitar.identificacion);
      // Si el participante a eliminar es el único registrado, eliminamos toda la entrada
      if (participantesFiltrados.length === 0) {
        return null; // No retornamos este item
      } else {
        return { ...item, participantes: participantesFiltrados };
      }
    }).filter(Boolean); // Filtramos los elementos nulos

    // Filtrar participante a eliminar de institucion_participantes
    let institucionParticipantesNew = institucion_participantes.map(item => {
      const participantesFiltrados = item.participantes.filter(participante => participante !== participante_quitar.identificacion);
      // Si el participante a eliminar es el único registrado, eliminamos toda la entrada
      if (participantesFiltrados.length === 0) {
        return null; // No retornamos este item
      } else {
        return { ...item, participantes: participantesFiltrados };
      }
    }).filter(Boolean); // Filtramos los elementos nulos


    // Actualizar estados
    this.setState({
      participantes: participantesNew,
      info_inicial_participantes: infoInicialParticipantesNew,
      financiacion_participantes: financiacionParticipantesNew,
      institucion_participantes: institucionParticipantesNew,
      modalDelParticipante: false,
      participante_quitar: 0,
      participantesSelect: [],
      cargando: false
    });

    // Mostrar mensaje de eliminación
    actualizarMensaje({ titulo: 'Participante Eliminado', tipo: 'danger', mostrar: true, tiempo: 6000 });
  }

  onChangeMulti = (selected, back, tipo) => {
    let resp = [];
    // let { backtiporesponsable } = this.state;
    selected.map((element) => {
      back.map((e) => {
        if (tipo == 'codigo') {
          if (element === e.codigo) return resp.push(e.nombre)
        } else {
          if (element === e.id) return resp.push(e.nombre)
        }
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }

  guardarParticipanteNuevoInt = async () => {
    let { actualizarMensaje } = this.props;
    let { datosParticipante: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion, identificacion, correo, indicativo_celular, celular, nacionalidad }, participantes, datosParticipante } = this.state;

    // Validar campos obligatorios
    let errores = await validateInputs([
      { value: primer_nombre, name: 'primer_nombre', err: { empty: true } },
      { value: primer_apellido, name: 'primer_apellido', err: { empty: true } },
      { value: tipo_identificacion, name: 'tipo_identificacion', err: { empty: true } },
      { value: identificacion, name: 'identificacion', err: { empty: true } },
      { value: nacionalidad, name: 'nacionalidad', err: { empty: true } },
    ]);

    // Si hay errores en los campos obligatorios, mostrar mensaje y salir de la función
    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }

    // Validar si la identificación ya está registrada
    let participanteExistente = participantes.find(participante => participante.identificacion === identificacion);
    if (participanteExistente) {
      actualizarMensaje({ titulo: 'Ya existe un participante registrado con esta identificación', tipo: 'info', mostrar: true, tiempo: 6000 });
      return; // Salir de la función si la identificación ya está registrada
    }

    // Si no hay errores y la identificación no está registrada, guardar el participante
    this.setState({ cargando: true });
    participantes.push(datosParticipante);
    this.setState({ participantes, modalAddParticipante: false, errores, datosParticipante: { primer_nombre: '', segundo_nombre: '', primer_apellido: '', segundo_apellido: '', tipo_identificacion: '', identificacion: '', nacionalidad: '', celular: '', correo_personal: '', correo: '', indicativo_celular: '' }, cargando: false, modalAddParticipantExt: false });
  }

  guardarParticipanteEmma = async (data) => {
    let { actualizarMensaje } = this.props;
    let { participantes } = this.state;
    this.setState({ cargando: true });
    console.log("EMMA")
    console.log(data)
    let datos = {
      id: data.id,
      primer_nombre: data.primer_nombre,
      segundo_nombre: data.segundo_nombre ?? '',
      primer_apellido: data.primer_apellido,
      segundo_apellido: data.segundo_apellido ?? '',
      tipo_identificacion: data.tipo_identificacion ?? '',
      identificacion: data.identificacion ?? '',
      correo: data.correo ?? '',
      correo_personal: data.correo_personal ?? '',
      indicativo_celular: data.indicativo_celular ?? '',
      celular: data.celular ?? '',
      nacionalidad: data.nacionalidad ?? '',
    }
    // this.setState({ cargando: true });
    participantes.push(datos);
    actualizarMensaje({ titulo: 'Participante agregado', tipo: 'success', mostrar: true, tiempo: 6000 });
    this.setState({ participantes, cargando: false });
  }

  // guardarInfoPrincipalParticipantes = async () => {
  //   let { actualizarMensaje } = this.props;
  //   const { participantesSelect, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, info_inicial_participantes } = this.state;
  //   let errores = await validateInputs([
  //     { value: tipo_movilidad, name: 'tipo_movilidad', err: { empty: true } },
  //     { value: tipo_modalidad, name: 'tipo_modalidad', err: { empty: true } },
  //     // { value: plataforma, name: 'plataforma', err: { empty: true } },
  //   ]);
  //   if (MODALIDADES_VIRTUALES.includes(tipo_modalidad) && plataforma === '') errores.push({ name: 'plataforma', err: { empty: true } });
  //   if (plataforma === 'otra' && otra_plataforma === '') errores.push({ name: 'otra_plataforma', err: { empty: true } });
  //   console.log(errores)

  //   if (errores.length === 0) {
  //     this.setState({ cargandoForm: true });
  //     let copia_info = [...info_inicial_participantes];
  //     let participantes = [];
  //     participantesSelect.forEach((participante) => {
  //       let { identificacion } = participante;
  //       participantes.push(identificacion);
  //     });
  //     let data = { participantes: participantes, tipo_movilidad: tipo_movilidad, tipo_modalidad: tipo_modalidad, plataforma: plataforma, otra_plataforma: otra_plataforma };
  //     copia_info.push(data);
  //     this.setState({ info_inicial_participantes: copia_info, modalAddInfoBasica: false });
  //   } else {
  //     this.setState({ errores });
  //     actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
  //   }
  //   this.setState({ cargandoForm: false });
  // }

  guardarInfoPrincipalParticipantes = async () => {
    const { actualizarMensaje } = this.props;
    const { participantesSelect, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, info_inicial_participantes } = this.state;

    // Validar campos obligatorios
    const errores = [];
    if (!tipo_movilidad) errores.push({ llave: 'tipo_movilidad', mensaje: 'Este campo es obligatorio' });
    if (!tipo_modalidad) errores.push({ llave: 'tipo_modalidad', mensaje: 'Este campo es obligatorio' });

    // Verificar plataforma en caso de modalidades virtuales
    if (MODALIDADES_VIRTUALES.includes(tipo_modalidad)) {
      if (!plataforma) errores.push({ llave: 'plataforma', mensaje: 'Este campo es obligatorio' });
      if (plataforma === OTRA_PLATAFORMA && !otra_plataforma) {
        errores.push({ llave: 'otra_plataforma', mensaje: 'Este campo es obligatorio' });
      }
    }

    // Mostrar errores si los hay
    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargandoForm: false });
      return;
    }

    // Verificar duplicados
    const duplicados = participantesSelect.some(participante => info_inicial_participantes.some(item => item.participantes.includes(participante.identificacion)));

    // Mostrar mensaje si hay duplicados
    if (duplicados) {
      actualizarMensaje({ titulo: 'Algunos de los participantes seleccionados ya tienen esta información registrada.', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargandoForm: false });
      return;
    }

    // Actualizar info_inicial_participantes
    const participantes = participantesSelect.map(participante => participante.identificacion);
    const data = { participantes, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma };
    this.setState(prevState => ({
      info_inicial_participantes: [...prevState.info_inicial_participantes, data],
      modalAddInfoBasica: false,
      cargandoForm: false
    }));
    this.resetForm('info_basica')
  };


  guardarFinanciacionParticipantes = async () => {
    const { participantesSelect, es_financiado, tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop, tipo_moneda, valor_ext, clasificacion_de_financiacion, otro_tipo_de_concepto, comprobante_financiacion, financiacion_participantes } = this.state;
    const { actualizarMensaje } = this.props;

    let errores = await validateInputs([
      { value: es_financiado, name: 'es_financiado', err: { empty: true } },
    ]);

    if (es_financiado == '1') {
      if (tipo_financiacion == '') errores.push({ llave: 'tipo_financiacion', 'mensaje': `Este campo es obligatorio` });
      if (tipo_financiacion == TIPO_FIN_INSTITUCIONAL) {
        if (dependencia == '') errores.push({ llave: dependencia, 'mensaje': `Este campo es obligatorio` });
      } else {
        if (fuente_financiacion == '') errores.push({ llave: 'fuente_financiacion', 'mensaje': `Este campo es obligatorio` });
        if (fuente_financiacion !== '') {
          if (entidad == '') errores.push({ llave: 'entidad', 'mensaje': `Este campo es obligatorio` });
        }
      }

      if (valor_cop == '') errores.push({ llave: 'valor_cop', 'mensaje': `Este campo es obligatorio` });
      if (tipo_moneda == '') errores.push({ llave: 'tipo_moneda', 'mensaje': `Este campo es obligatorio` });
      if (tipo_moneda != 'none' && tipo_moneda != '') {
        if (valor_ext == '') errores.push({ llave: 'valor_ext', 'mensaje': `Este campo es obligatorio` });
      }
      if (clasificacion_de_financiacion == '') errores.push({ llave: 'clasificacion_de_financiacion', 'mensaje': `Este campo es obligatorio` });
      if (clasificacion_de_financiacion == CODIGO_TIPO_CONCEPTO_OTRO) {
        if (otro_tipo_de_concepto == '') errores.push({ llave: 'otro_tipo_de_concepto', 'mensaje': `Este campo es obligatorio` });
      }

    }

    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargandoForm: false });
      return;
    }

    // Verificar duplicados
    const duplicados = participantesSelect.some(participante => financiacion_participantes.some(item => item.participantes.includes(participante.identificacion)));

    // Mostrar mensaje si hay duplicados
    if (duplicados) {
      actualizarMensaje({ titulo: 'Algunos de los participantes seleccionados ya tienen esta información registrada.', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargandoForm: false });
      return;
    }

    // Actualizar financiacion_participantes
    const participantes = participantesSelect.map(participante => participante.identificacion);
    const data = { participantes, es_financiado, tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop, tipo_moneda, valor_ext, clasificacion_de_financiacion, otro_tipo_de_concepto };
    this.setState(prevState => ({
      financiacion_participantes: [...prevState.financiacion_participantes, data],
      modalAddFinanciacion: false,
      cargandoForm: false
    }));
    this.resetForm('financiacion')
  }

  guardarInstitucionParticipante = async () => {
    let { actualizarMensaje } = this.props;
    let { institucion_participante, tipo_participante, nivel_participante, programa_participante, dpto_participante, facultad_participante, dependencia_participante, institucion_participantes, participantesSelect } = this.state;

    let errores = await validateInputs([]);

    if (institucion_participante.institucion == '') errores.push({ llave: 'institucion_participante', 'mensaje': `Este campo es obligatorio` });
    if (tipo_participante == null) errores.push({ llave: 'tipo_participante', 'mensaje': `Este campo es obligatorio` });

    if (tipo_participante == TIPO_PARTICIPANTE_ESTU) {
      if (facultad_participante == '') errores.push({ llave: 'facultad_participante', 'mensaje': `Este campo es obligatorio` });
      if (nivel_participante == '') errores.push({ llave: 'nivel_participante', 'mensaje': `Este campo es obligatorio` });
      if (programa_participante == '') errores.push({ llave: 'programa_participante', 'mensaje': `Este campo es obligatorio` });
    } else if (tipo_participante == TIPO_PARTICIPANTE_DOC) {
      if (dpto_participante == '') errores.push({ llave: 'dpto_participante', 'mensaje': `Este campo es obligatorio` });
      if (programa_participante == '') errores.push({ llave: 'programa_participante', 'mensaje': `Este campo es obligatorio` });
    } else if (tipo_participante == TIPO_PARTICIPANTE_ADMIN) {
      if (dpto_participante == '') errores.push({ llave: 'dpto_participante', 'mensaje': `Este campo es obligatorio` });
      if (programa_participante == '') errores.push({ llave: 'programa_participante', 'mensaje': `Este campo es obligatorio` });
    }
    console.log(errores)

    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }

    // VERIFICAR SI HAY DUPLICADOS
    const duplicados = participantesSelect.some(participante => institucion_participantes.some(item => item.participantes.includes(participante.identificacion)));

    // Mostrar mensaje si hay duplicados
    if (duplicados) {
      actualizarMensaje({ titulo: 'Algunos de los participantes seleccionados ya tienen esta información registrada.', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargandoForm: false });
      return;
    }

    // Actualizar institucion_participantes
    const participantes = participantesSelect.map(participante => participante.identificacion);

    let data = { participantes, institucion: institucion_participante.id, tipo_participante, nivel_participante, programa_participante, facultad_participante, dpto_participante, programa_participante };

    // data = { ...data, nivel_participante, programa_participante, facultad_participante, dpto_participante, programa_participante };
    // if (tipo_participante == TIPO_PARTICIPANTE_ESTU) {
    // } else if (tipo_participante == TIPO_PARTICIPANTE_DOC || tipo_participante == TIPO_PARTICIPANTE_ADMIN) {
    //   data = { ...data, dpto_participante, programa_participante };
    // }

    this.setState(prevState => ({
      institucion_participantes: [...prevState.institucion_participantes, data],
      modalAddInstitucion: false,
      cargandoForm: false
    }));
    this.resetForm('institucion')
  }

  guardarActividadParticipantes = () => {
    const { participantesSelect, tipo_actividad, otro_tipo_actividad, subtipo_actividad, otro_subtipo_actividad, fecha_inicio, fecha_fin, responsable_actividad, dpto_responsable, facultad_responsable, administrativos_responsable, actividad_participantes, info_complementaria, titulo_tema, descripcion_actividad, resultados_descripcion } = this.state;
    let copia_info = [...actividad_participantes];
    let participantes = [];
    participantesSelect.forEach((participante) => {
      let { identificacion } = participante;
      participantes.push(identificacion);
    });
    let data = { participantes: participantes, tipo_actividad: tipo_actividad, otro_tipo_actividad: otro_tipo_actividad, subtipo_actividad: subtipo_actividad, otro_subtipo_actividad: otro_subtipo_actividad, fecha_inicio: fecha_inicio ? moment(fecha_inicio).format("YYYY-MM-DD") : "", fecha_fin: fecha_fin ? moment(fecha_fin).format("YYYY-MM-DD") : "", responsable_actividad: responsable_actividad, dpto_responsable: dpto_responsable, facultad_responsable: facultad_responsable, administrativos_responsable: administrativos_responsable, info_complementaria: info_complementaria, titulo_tema: titulo_tema, descripcion_actividad: descripcion_actividad, resultados_descripcion: resultados_descripcion };
    copia_info.push(data);
    this.setState({ actividad_participantes: copia_info, modalAddActividad: false });
  }

  agregarInstitucionParticipante = (id, institucion, pais_vinculante, ciudad_vinculante) => {
    let { errores } = this.state;
    this.setState({ institucion_participante: { id: id, institucion, pais_vinculante: pais_vinculante ?? '', ciudad_vinculante: ciudad_vinculante ?? '' }, errores: errores.length > 0 ? errores.filter(er => er.llave !== 'institucion_participante') : errores });
  }

  resetForm = (tipo) => {
    if (tipo === 'participante') {
      this.setState({ datosParticipante: { primer_nombre: '', segundo_nombre: '', primer_apellido: '', segundo_apellido: '', tipo_identificacion: '', identificacion: '', nacionalidad: '', celular: '', correo_personal: '', correo: '', indicativo_celular: '' }, errores: [] });
    } else if (tipo === 'info_basica') {
      this.setState({ tipo_movilidad: '', tipo_modalidad: '', plataforma: '', otra_plataforma: '' });
    } else if (tipo === 'financiacion') {
      this.setState({ es_financiado: '', tipo_financiacion: '', dependencia: '', fuente_financiacion: '', entidad: '', valor_cop: '', tipo_moneda: '', valor_ext: '', clasificacion_de_financiacion: '', otro_tipo_de_concepto: '', comprobante_financiacion: '', error: false, participante_tipo: null, tipos_participantes: [], tipo_participante: null });
    } else if (tipo === 'institucion') {
      this.setState({ institucion_participante: { id: 0, institucion: '', pais_vinculante: '', ciudad_vinculante: '' }, tipo_participante: '', nivel_participante: '', programa_participante: '', dpto_participante: '', facultad_participante: '', dependencia_participante: '' });
    }
    // else if (tipo === 'actividad') {
    //   this.setState({ tipo_actividad: '', otro_tipo_actividad: '', subtipo_actividad: '', otro_subtipo_actividad: '', fecha_inicio: null, fecha_fin: null, responsable_actividad: [], dpto_responsable: [], facultad_responsable: [], administrativos_responsable: [], titulo_tema: '', descripcion_actividad: '', resultados_descripcion: '', info_complementaria: '' });
    // }

  }

  submit = async (archivos) => {
    let { actualizarMensaje } = this.props;
    if (archivos.length > 0) {
      // this.setState({ cargandoForm: true });
      this.submitForm(archivos);
    } else actualizarMensaje({ titulo: 'Debe adjuntar por lo menos una evidencia.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  submitForm = async (archivos) => {
    let { actualizarMensaje, guardarRegistro } = this.props;
    let { participantes, info_inicial_participantes, financiacion_participantes, tipo_actividad, otro_tipo_actividad, actividad_participantes, institucion_participantes, info_complementaria, info_complementaria_f, departamento_docente } = this.state;
    this.setState({ cargando: true, enviando: true });
    let data = new FormData();
    if (info_complementaria) data.append('info_complementaria', info_complementaria_f);
    // data.append({ archivo: new File([info_complementaria], ''), codigo: 1250 });
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo);
      data.append(`codigo${index}`, codigo);
    })
    data.append('total_evidencias', archivos.length);
    data.append('participantes', JSON.stringify(participantes));
    data.append('info_inicial_participantes', JSON.stringify(info_inicial_participantes));
    data.append('actividad_participantes', JSON.stringify(actividad_participantes));
    data.append('financiacion_participantes', JSON.stringify(financiacion_participantes));
    data.append('institucion_participantes', JSON.stringify(institucion_participantes));
    data.append('departamento_docente', departamento_docente)

    const reset = () => {
      this.setState({ cargando: false, modalAddRegistro: false, enviando: false, tipo_actividad: '', subtipo_actividad: '', otro_tipo_actividad: '', otro_subtipo_actividad: '', fecha_inicio: null, fecha_fin: null, responsable_actividad: [], dpto_responsable: [], facultad_responsable: [], administrativos_responsable: [], titulo_tema: '', descripcion_actividad: '', resultados_descripcion: '', info_complementaria: '', info_complementaria_f: null, participantes: [], info_inicial_participantes: [], financiacion_participantes: [], institucion_participantes: [], actividad_participantes: [], instituciones: [], tipo_movilidad: '', tipo_modalidad: '', plataforma: '', otra_plataforma: '', participantesSelect: [], es_financiado: '', tipo_financiacion: '', dependencia: '', fuente_financiacion: '', entidad: '', valor_cop: '', tipo_moneda: '', valor_ext: '', clasificacion_de_financiacion: '', otro_tipo_de_concepto: '', comprobante_financiacion: '', participante_tipo: null, tipos_participantes: [], tipo_participante: null, errores: [], institucion_participante: { id: 0, institucion: '', pais_vinculante: '', ciudad_vinculante: '' }, facultad_participante: '', nivel_participante: '', programa_participante: '', dpto_participante: '', dependencia_participante: '', actividad_participante: '', financiacion_participantes: [], activeStep: 0, mostrarForm: false });
    }

    await guardarRegistro(data, reset);
  }


  enviarParticipantesMasivo = async (archivo) => {
    let { actualizarMensaje } = this.props;
    if (archivo.length === 0) return true;
    this.setState({ modalAddParticipanteMasivo: false, cargando: true })
    let data = await crear_form_data({ archivo });
    formulario(`api/v1.0/internacionalizacion/obtener_datosParticipantesMasivo`, data, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.cargarParticipante(resp.participantes_agregados)
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          this.setState({ cargandoForm: false })
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  // ☢️ CONSTRUCCION 
  cargarParticipante = async (participantes_masivos) => {
    let { actualizarMensaje } = this.props;
    let { participantes } = this.state;
    this.setState({ cargando: true });
    let participantesNuevos = [];
    participantes_masivos.map((participante, index) => {
      let { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion, identificacion, correo, correo_personal, indicativo_celular, celular, nacionalidad } = participante;
      console.log(participante)
      let data = { id: index, primer_nombre, segundo_nombre: segundo_nombre ?? '', primer_apellido, segundo_apellido: segundo_apellido ?? '', tipo_identificacion, identificacion, correo: correo ?? '', correo_personal: correo_personal ?? '', indicativo_celular: indicativo_celular ?? '', celular: celular ?? '', nacionalidad: nacionalidad ?? '' };
      participantesNuevos.push(data);
    });
    participantes = participantes.concat(participantesNuevos);
    this.setState({ participantes, cargando: false });

  }

  pintarParticipantes() {
    let { participantesSelect, participantes, info_inicial_participantes, institucion_participantes, financiacion_participantes, selectAll, tipos_movilidades_all, tipos_identificacion, cargando, } = this.state;
    const acciones = (data, tipo) => {
      if (tipo === 'participante') {
        const onClickDetalle = data => {
          let tipo_identificacion = tipos_identificacion.find(tipo => tipo.id === (typeof data.tipo_identificacion === 'object' ? data.tipo_identificacion.id : data.tipo_identificacion));
          console.log(tipo_identificacion)
          let participanteDetalle = { ...data }
          participanteDetalle.tipo_identificacion = { nombre: tipo_identificacion.nombre, id: tipo_identificacion.id };
          // data.tipo_identificacion = { nombre: tipo_identificacion.nombre, id: tipo_identificacion.id};
          this.setState({ modalDetParticipante: true, participanteDetalle: participanteDetalle});
        }
        const onClickQuitar = data => this.setState({ modalDelParticipante: true, participante_quitar: data });
        return (
          <>
            <Tooltip title="Seleccionar">
              <Checkbox edge="start" onChange={() => this.handleToggle(data.identificacion, data.id)} checked={this.isParticipanteSelected(data.identificacion, data.id)} style={{ color: '#2196f3' }} />
            </Tooltip>
            <BtnDetalle callback={() => onClickDetalle(data)} texto='Detalle' />
            {/* <BtnEditar callback={() => onClickDetalle(data)} texto='Editar' color='#328e32' /> */}
            <BtnCancelar callback={() => onClickQuitar(data)} texto='Quitar' color='#cc0000' />
          </>
        );
      } else {
        return (
          <Tooltip title="Seleccionar todo">
            <Checkbox onChange={() => this.handleSelectAllToggle()} checked={this.state.selectAll} style={{ color: '#2196f3' }} />
          </Tooltip>
        );
      }
    };

    const renderIconos = (data, tipo) => {
      const participantesData = { 'info_basica': info_inicial_participantes, 'financiacion': financiacion_participantes, 'institucion': institucion_participantes };
      const info_inicial = participantesData[tipo].find(participante => participante.participantes.includes(data.identificacion));
      return <CheckBoxIcon color={info_inicial ? 'primary' : 'disabled'} />;
    };

    const renderMovilidad = (data) => {
      const info_inicial = info_inicial_participantes.find(participante => participante.participantes.includes(data.identificacion));
      if (info_inicial) {
        const tipoMovilidadId = info_inicial.tipo_movilidad;
        const tipoMovilidadEncontrado = tipos_movilidades_all.find(tipo => tipo.id === tipoMovilidadId);
        return tipoMovilidadEncontrado ? tipoMovilidadEncontrado.nombre : '';
      } else return 'No registra'
    };

    return (
      <>
        <Grid container spacing={2} style={{}}>
          <Grid item xs={12}>
            {cargando && <TareasTerminadas mensaje={'Cargando..'} marginTop='1%' imagen={emma_s1} widthImg="9%" style={{ minWidth: '60%' }} />}
            {(participantes.length > 0 && !cargando) ?
              <div style={{ margin: '0 0 3% 0 ' }}>
                <div style={{ display: 'flex', justifyContent: 'end', margin: '0% 0 1% 0' }}>
                  <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} disabled={participantesSelect.length === 0} onClick={() => participantesSelect.length > 0 && this.setState({ modalContenedor: true })} >
                    <AddIcon />
                  </Button>
                </div>

                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '5%', marginTop: '3%' }}>
                  <TableContainer sx={{ maxWidth: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow key={0} style={{ padding: '5px', justifyContent: 'space-between' }} >
                          <TableCell style={{ minWidth: '2rem', maxWidth: '9.5rem', padding: '3px' }} >{acciones(participantes, 'todo')}</TableCell>
                          <TableCell style={{ minWidth: '2rem', maxWidth: '9rem', padding: '3px', textAlign: 'center' }} >NOMBRE</TableCell>
                          <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >IDENTIFICACIÓN</TableCell>
                          <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >TIPO MOVILIDAD</TableCell>
                          <TableCell style={{ minWidth: '2rem', maxWidth: '4rem', padding: '3px', textAlign: 'center' }} >INFORMACIÓN BÁSICA</TableCell>
                          <TableCell style={{ minWidth: '2rem', maxWidth: '5rem', padding: '3px', textAlign: 'center' }} >FINANCIACIÓN</TableCell>
                          <TableCell style={{ minWidth: '2rem', maxWidth: '4rem', padding: '3px', textAlign: 'center' }} >INSTITUCIÓN</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantes.map((participante, i) => {
                          let nombre_completo = `${participante.primer_nombre ?? ''} ${participante.segundo_nombre ?? ''} ${participante.primer_apellido ?? ''} ${participante.segundo_apellido ?? ''}`;
                          return (
                            <TableRow key={i} style={{ padding: '5px', justifyContent: 'space-between' }}>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '9.5rem' }} >{acciones(participante, 'participante')}</TableCell>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '9rem', padding: '3px', textAlign: 'center' }}>{nombre_completo.toUpperCase()}</TableCell>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >{participante.identificacion}</TableCell>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >{renderMovilidad(participante)}</TableCell>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '4rem', textAlign: 'center' }} >{renderIconos(participante, 'info_basica')}</TableCell>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '5rem', textAlign: 'center' }} >{renderIconos(participante, 'financiacion')}</TableCell>
                              <TableCell style={{ minWidth: '2rem', maxWidth: '4rem', textAlign: 'center' }} >{renderIconos(participante, 'institucion')}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
              :
              <TareasTerminadas mensaje={<React.Fragment>  No hay participantes registrados. <br />  Haga clic en el botón para añadir un nuevo participante</React.Fragment>} marginTop='1%' imagen={emma_s1} widthImg="9%" style={{ minWidth: '60%' }} />
            }
          </Grid>
        </Grid >
        <div style={{ textAlign: 'center', position: 'sticky', bottom: '0', zIndex: '1000', backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white', marginRight: '8px' }} onClick={() => this.setState({ modalAddParticipante: true })}>Añadir participante<PersonAddIcon style={{ marginLeft: '0.6rem' }} /></Button>
          <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} onClick={() => this.setState({ modalAddParticipanteMasivo: true })}>Participante Masivo <PublishIcon style={{ marginLeft: '0.6rem' }} /></Button>
        </div>
      </>
    )
  }

  renderEvidencias = () => {
    // let { certificados_participantes, enlace_grabacion, modalidad_escogida, evidenciasAdjuntos } = this.state;
    let { evidencias, cargandoForm } = this.state;
    const trasnformarDataAdj = (data) => {
      let r = [];
      data.map(({ secundario }) => r.push({ nombre: secundario.nombre, codigo: secundario.id, archivo: secundario.archivo, formatos: secundario.valorg }))
      return r;
    }
    const acciones = (data, callback) => {
      const onClickCargar = data => callback(data);
      let descargar = data.archivo && <BtnDescargar callback={() => window.open(`${data.archivo}`, '_blank')} color='#01579b' />
      let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' />
      return <div>{descargar} {cargar}</div>;
    }
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={0} square>
              <List component='nav' arial-label='main'>
                {cargandoForm && <TareasTerminadas mensaje={'Cargando evidencias..'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />}
                {evidencias && evidencias.length > 0 && !cargandoForm &&
                  <EnviarMultiplesArchivos
                    acciones={acciones}
                    enModal={false}
                    enviarArchivos={(archivos) => this.submit(archivos)}
                    limite={2}
                    mostrarMensaje={false}
                    enLista={true}
                    lista={trasnformarDataAdj(evidencias)}
                    formatos={true}
                  />}
              </List>
            </Paper>
          </Grid>
          {/* {
            CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) &&
              <Grid item xs = { 6 }>
                <TextField
                  value = { enlace_grabacion }
                  id = 'enlace_grabacion'
                  name = 'enlace_grabacion'
                  label = 'Enlace de grabación'
                  fullWidth
                  onChange = { this.onChange }
                />
              </Grid>
          }
          <Grid item xs = { CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) ? 6 : 12 }>
            <TextField
              className = 'oculto'
              ref = { this.fileInput }
              type = "file"
              id = "certificados_participantes"
              name = "certificados_participantes"
              label = 'Certificados de participantes'
              fullWidth
              autoComplete = "billing address-line1"
              onChange = { this.onChange }
            />
            <Grid item xs = { 12 }>
              <InputFile label = 'Certificados de participantes' id = 'certificados_participantes' required = { false } value = { certificados_participantes }/>
            </Grid>
          </Grid> */}
        </Grid>
      </React.Fragment>
    )
  }

  configStepContent = () => {
    let { activeStep, mostrarForm } = this.state;
    let { instructivo } = this.props
    return (
      <>
        {activeStep === 0 && <AgregarActividad state={this.state} actions={this} instructivo={instructivo} />}
        {activeStep === 1 && this.pintarParticipantes()}
        {activeStep === 2 && this.renderEvidencias()}
      </>
    );
  }

  handleStep = async (step) => {
    let { activeStep, departamento_docente, tipo_actividad, instituciones, fecha_inicio, fecha_fin, responsable_actividad, administrativos_responsable, titulo_tema, descripcion_actividad, resultados_descripcion, dpto_responsable, facultad_responsable, participantes, info_inicial_participantes, financiacion_participantes, institucion_participantes, otro_subtipo_actividad, otro_tipo_actividad, subtipo_actividad, info_complementaria } = this.state;
    let { actualizarMensaje } = this.props;
    let errores = [];
    switch (activeStep) {
      case 0:
        errores = await validateInputs([
          { value: departamento_docente, name: 'departamento_docente', err: { empty: true } },
          { value: tipo_actividad, name: 'tipo_actividad', err: { empty: true } },
          { value: instituciones, name: 'instituciones', err: { empty: true } },
          { value: fecha_inicio, name: 'fecha_inicio', err: { not_null: true } },
          { value: fecha_fin, name: 'fecha_fin', err: { not_null: true } },
          { value: responsable_actividad, name: 'responsable_actividad', err: { empty: true } },
          { value: titulo_tema, name: 'titulo_tema', err: { empty: true } },
          { value: descripcion_actividad, name: 'descripcion_actividad', err: { empty: true } },
          { value: resultados_descripcion, name: 'resultados_descripcion', err: { empty: true } },
        ])
        if (responsable_actividad.includes('tipo_resp_admin')) {
          if (administrativos_responsable.length === 0) errores.push({ llave: 'administrativos_responsable', 'mensaje': `Seleccione al menos una unidad administrativa` });
        }
        if (responsable_actividad.includes('tipo_resp_dpto')) {
          if (dpto_responsable.length === 0) errores.push({ llave: 'dpto_responsable', 'mensaje': `Seleccione al menos un departamento` });
        }
        if (responsable_actividad.includes('tipo_resp_facul')) {
          if (facultad_responsable.length === 0) errores.push({ llave: 'facultad_responsable', 'mensaje': `Seleccione al menos una facultad` });
        }
        if (fecha_inicio && fecha_fin) {
          let fechaInicio = new Date(fecha_inicio);
          let fechaFin = new Date(fecha_fin);
          if (fechaInicio > fechaFin) {
            errores.push({ llave: 'fecha_inicio', mensaje: 'Error en las fechas' });
            errores.push({ llave: 'fecha_fin', mensaje: 'Error en las fechas' });
            actualizarMensaje({ titulo: 'La fecha de inicio no puede ser posterior a la fecha final.', tipo: 'info', mostrar: true, tiempo: 6000 });
          }
        }
        // FALTAN MAS POR AGG
        // otro_subtipo_actividad
        // otro_tipo_actividad
        if (errores.length > 0) {
          this.setState({ errores });
          actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
        } else {
          let actividad = [
            { tipo_actividad: tipo_actividad, otro_tipo_actividad: otro_tipo_actividad, subtipo_actividad: subtipo_actividad, otro_subtipo_actividad: otro_subtipo_actividad, fecha_inicio: fecha_inicio ? moment(fecha_inicio).format("YYYY-MM-DD") : "", fecha_fin: fecha_fin ? moment(fecha_fin).format("YYYY-MM-DD") : "", responsable_actividad: responsable_actividad, dpto_responsable: dpto_responsable, facultad_responsable: facultad_responsable, administrativos_responsable: administrativos_responsable, titulo_tema: titulo_tema, descripcion_actividad: descripcion_actividad, resultados_descripcion: resultados_descripcion, instituciones: instituciones }
          ]
          this.setState({ actividad_participantes: actividad });
        }
        break;
      case 1:
        if (participantes.length === 0) {
          errores.push({ llave: 'participantes', 'mensaje': `Debes añadir al menos un participante.` });
          actualizarMensaje({ titulo: 'Debes añadir al menos un participante.', tipo: 'info', mostrar: true, tiempo: 6000 });
        } else {
          // Crear un conjunto que almacene todas las identificaciones únicas de participantes en cada categoría
          const participantesInfoInicial = new Set(info_inicial_participantes.flatMap(item => item.participantes));
          const participantesFinanciacion = new Set(financiacion_participantes.flatMap(item => item.participantes));
          const participantesInstitucion = new Set(institucion_participantes.flatMap(item => item.participantes));

          // Obtener identificaciones únicas de participantes registrados
          const participantesRegistrados = new Set(participantes.map(participante => participante.identificacion));

          // Verificar si todos los participantes registrados están presentes en todas las categorías
          const todosRegistrados = [...participantesRegistrados].every(participante =>
            participantesInfoInicial.has(participante) && participantesFinanciacion.has(participante) && participantesInstitucion.has(participante)
          );

          if (!todosRegistrados) {
            errores.push({ llave: 'participantes', 'mensaje': `Falta información por registrar.` });
            actualizarMensaje({ titulo: 'Falta información por registrar.', tipo: 'info', mostrar: true, tiempo: 6000 });
          }
        }
        break;
      case 2:
        errores = this.validarFinanciacion();
        break;
    }
    this.setState({ errores });
    if (errores.length === 0) {
      if (activeStep == 0) this.setState({ activeStep: activeStep + 1 });
      if (activeStep == 1) this.setState({ activeStep: activeStep + 1 });
    }
    // this.setState({ activeStep: activeStep + 1 });
  }

  handleModalContenedor = async (codigo) => {
    return (
      <>
        {codigo === 'add_info_basica' && this.setState({ modalAddInfoBasica: true })}
        {codigo === 'add_financiacion' && this.setState({ modalAddFinanciacion: true })}
        {codigo === 'add_institucion' && this.setState({ modalAddInstitucion: true })}
      </>
    )
  }

  render() {
    let { modalAddRegistro, mostrarModalAddRegistro, mostrarModalCancelarSolicitud, set_datos_solicitud } = this.props;
    let { activeStep, tipos_forms, participantes, participantesSelect, borrador_activo, cargando, tit_cargando, solicitud_iniciada, check_masivo, modalAddParticipante, datosParticipante, tipos_identificacion, cargandoForm, errores, modalDelParticipante, participante_quitar, modalAddActividad, modalAddFinanciacion, modalAddInfoBasica, modalContenedor, tipos_movilidades, tipos_modalidades, plataformas, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, tipo_actividad, otro_tipo_actividad, subtipo_actividad, otro_subtipo_actividad, tipos_actividades, subtipos_actividades, fecha_inicio, fecha_fin, tipos_responsables, responsable_actividad, dpto_responsable, facultad_responsable, administrativos_responsable, departamentos_resp, facultades_resp, administrativos_resp, titulo_tema, descripcion_actividad, resultados_descripcion, info_complementaria, es_financiado, tipos_financiaciones, tipo_financiacion, dependencia, dependencias, fuente_financiacion, fuentes_financiacion, entidad, valor_cop, tipo_moneda, monedas, valor_ext, info_inicial_participantes, tipos_conceptos, clasificacion_de_financiacion, otro_tipo_de_concepto, comprobante_financiacion, modalAddParticipanteMasivo, modalDetParticipante, participanteDetalle, modalAddInstitucion, modalAddEvidencia, institucion_participante, modal_buscar_institucion, actividad_participantes, mostrarForm, instituciones, institucion_seleccionada, participante_tipo, masivos, enviando } = this.state;

    // PROPS PARA EL COMPONENTE DE INFORMACIÓN BÁSICA
    let props_info = { modalAddInfoBasica, cerrarModal: () => this.setState({ modalAddInfoBasica: false }), cargandoForm, errores, onChange: this.onChange, tipos_movilidades, tipos_modalidades, plataformas, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, guardarInfoPrincipalParticipantes: this.guardarInfoPrincipalParticipantes }


    // PROPS PARA EL COMPONENTE DE FINANCIACIÓN
    let props_financiación = { participantesSelect, modalAddFinanciacion, cerrarModal: () => this.setState({ modalAddFinanciacion: false }), cargandoForm, onChange: this.onChange, es_financiado, tipos_financiaciones, tipo_financiacion, errores, dependencia, dependencias, fuente_financiacion, fuentes_financiacion, entidad, valor_cop, tipo_moneda, monedas, valor_ext, info_inicial_participantes, tipos_conceptos, clasificacion_de_financiacion, otro_tipo_de_concepto, fileInput: this.fileInput, comprobante_financiacion, guardarFinanciacionParticipantes: this.guardarFinanciacionParticipantes }

    // PROPS PARA EL COMPONENTE DE INSTITUCIÓN
    let props_institucion = { modalAddInstitucion, cerrarModal: () => this.setState({ modalAddInstitucion: false }), cargandoForm, errores, institucion_participante, modal_buscar_institucion, agregarInstitucionParticipante: this.agregarInstitucionParticipante, cerrarModalBuscarInstitucion: () => this.setState({ modal_buscar_institucion: false }), abrirBuscarInst: () => this.setState({ modal_buscar_institucion: true }), participante_tipo }

    return (
      <>
        <Dialog fullWidth={true} maxWidth={(participantes && participantes.length > 0 && activeStep == 1) ? 'lg' : 'md'} open={modalAddRegistro} onClose={() => mostrarModalAddRegistro(false)} disableBackdropClick={true}>
          <AppBarModal titulo={'¡ Agregar Registro de Movilidad !'} mostrarModal={() => mostrarModalAddRegistro(false)} />
          {
            !cargando ?
              <DialogContent className='scroll'>
                <Grid container justifyContent='center'>
                  {mostrarForm &&
                    <Grid item xs={9} md={9}>
                      <Stepper activeStep={activeStep}>
                        {
                          configStep.map(label => (
                            <Step key={label}>
                              <StepLabel><p>{label}</p></StepLabel>
                            </Step>
                          ))
                        }
                      </Stepper>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    {this.configStepContent()}
                  </Grid>
                </Grid>
              </DialogContent>
              : <TareasTerminadas mensaje={tit_cargando} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          }
          <DialogActions>
            {
              activeStep === 0 && mostrarForm &&
              <>
                <BtnForm texto="CERRAR" callback={() => mostrarModalAddRegistro(false)} />
                <BtnForm texto="SIGUIENTE" callback={() => this.handleStep()} />
              </>
            }
            {
              activeStep === 1 &&
              <>
                <BtnForm texto="CERRAR" callback={() => mostrarModalAddRegistro(false)} />
                <BtnForm texto="REGRESAR" callback={() => this.setState({ activeStep: activeStep - 1 })} />
                <BtnForm texto="SIGUIENTE" callback={() => this.handleStep()} />
              </>
            }
            {
              activeStep === 2 && !enviando &&
              <>
                <BtnForm texto="CERRAR" callback={() => mostrarModalAddRegistro(false)} />
                <BtnForm texto="REGRESAR" callback={() => this.setState({ activeStep: activeStep - 1 })} />
                <BtnForm texto="FINALIZAR" callback={() => document.getElementById('enviar_form_multi_archivo').click()} />
              </>
            }
            {/* <>
              <BtnForm texto="CERRAR" callback={() => mostrarModalAddRegistro(false)} />
              <BtnForm texto="GUARDAR" callback={() => this.submitForm()} />
            </> */}
          </DialogActions>
        </Dialog>

        {/* MODAL CONTENEDOR */}
        {modalContenedor && <ModalContenedor state={this.state} actions={this} />}

        {/* AÑADIR PARTICIPANTE */}
        {modalAddParticipante && <AgregarParticipante state={this.state} actions={this} editar={false}/>}


        {/* AÑADIR INFORMACIÓN BÁSICA */}
        {modalAddInfoBasica && <AgregarInfoBasica props_info={props_info} />}

        {/* AÑADIR FINANCIACIÓN */}
        {modalAddFinanciacion && <AgregarFinanciacion props_financiación={props_financiación} />}

        {/* AÑADIR INSTITUCIÓN */}
        {modalAddInstitucion && <AgregarInstitucion state={this.state} actions={this} props_institucion={props_institucion} />}

        {/* AÑADIR PARTICIPANTES MASIVOS */}
        {modalAddParticipanteMasivo && <AgregarParticipanteMasivo modalMasivo={modalAddParticipanteMasivo} cerrarModal={() => this.setState({ modalAddParticipanteMasivo: false })} enviarParticipantesMasivo={this.enviarParticipantesMasivo} masivos={masivos} ca />}

        {/* DETALLE PARTICIPANTE */}
        {modalDetParticipante && <DetalleParticipante modalDetParticipante={modalDetParticipante} cerrarModal={() => this.setState({ modalDetParticipante: false })} participanteDetalle={participanteDetalle} cargando={false} cargandoForm={cargandoForm} />}


        {/* CONFIRMAR ELIMINAR PARTICIPANTE */}
        <ConfirmarAccion
          mensaje1={
            <React.Fragment>
              ¿Estás seguro de quitar a <b>{participante_quitar.primer_nombre} {participante_quitar.segundo_nombre} {participante_quitar.primer_apellido} {participante_quitar.segundo_apellido}</b> de la lista de participantes?
              <br />
              {/* <Alert severity="info" variant="outlined"> */}
              Recuerda que si eliminas al participante, se eliminará la información guardada.
              {/* </Alert> */}
            </React.Fragment>
          }
          ejecutarAccion={this.quitarParticipante}
          mostrarModalConfAccion={(modalDelParticipante) => this.setState({ modalDelParticipante })}
          modalConAccion={modalDelParticipante}
          dato={{ id: 0 }}
          titulo={'¿ Quitar participante ?'}
          mensaje2={''}
        />



      </>
    )
  }


}

export default RegistroCrear;